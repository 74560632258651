import * as React from 'react';
import AllRanklistModel from '../components/AllRanklistModel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import CertificateView from '../components/CertificateView';
import {AsyncStorageService} from '../services/AsyncStorage';


interface Props{

}

interface State{
    [x: number]: any; 
    dandiAll:any;
    dandiFinisher:any;
    searchAll:any;
    searchFinisher:any;
    Vadata: any;
    fYear: any;
    icon: any;
    title: any;
    top5manRunner: any;
    top5manCyclist: any;
    top5womanRunner: any;
    top5womanCyclist: any;
    top5cycling: any;
    top5running: any;
    top5walking: any;
    top5stairs:any;
    allOver: any;
    link:any;
    userId:any;
}

class DandiDadhboard extends React.Component<Props, State>{
    constructor(props){
        super(props);
        this.state={
            dandiAll:[],
            dandiFinisher:[],
            searchAll:null,
            fYear: '',
            icon: '',
            title: '',
            searchFinisher:null,
            Vadata: [],
            top5manRunner: [],
            top5manCyclist:[],
            top5womanRunner: [],
            top5womanCyclist:[],
            top5cycling: [],
            top5running: [],
            top5walking: [],
            top5stairs:[],
            allOver: [],
            link:'',
            userId:''
        };
    }

    componentDidMount=async()=>{
        const user =  await AsyncStorageService.getUser();
        const date = new Date();
        const currentYear:any = date.getFullYear();
        this.setState({userId:user.user_id, fYear: currentYear });

       
        this.top5Leadership(currentYear);
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('year', currentYear);

        await axios.post("https://api.cyruns.in/api/athletes.php?action=navratrileadership", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    const dandiAll = response.data.dandiall;
                    const dandiFinisher  = response.data.finishers;

                    this.setState({
                        dandiAll,dandiFinisher
                    });
                } else {
                    toast.error(response.data.msg)
                }
            })
    }
    top5Leadership = async (Year) => {
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('year', Year);
        formData.append('gender_type', 'all');
        await axios.post("https://api.cyruns.in/api/athletes.php?action=top5leadershipboardnavratri", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    this.setState({
                        top5manRunner: response.data.top_5_men_runner,
                        top5womanRunner: response.data.top_5_women_runner,
                        top5manCyclist: response.data.top_5_men_cycling,
                        top5womanCyclist: response.data.top_5_women_cycling,
                        top5walking: response.data.top_5_walking,
                        top5stairs:response.data.top_5_stairs
                    });
                } else {
                    toast.error(response.data.msg)
                }
            })
    }
    searchSpace=(event)=>{
        let {value,name} = event.target;
        this.setState({[name]:value});
      }
      viewAll=async(gender,type)=>{
        const {fYear} = this.state;
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('year', fYear);
        formData.append('gender', gender);
        formData.append('type', type);

        await axios.post("https://api.cyruns.in/api/athletes.php?action=top5alldatanavratri", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    this.setState({
                        Vadata: response.data.view_all
                    });
                } else {
                    toast.error(response.data.msg)
                }
            })
    }  
    render(){
        const {dandiAll,dandiFinisher,searchAll,searchFinisher,link,userId,top5manRunner,top5manCyclist, top5womanRunner, top5womanCyclist, top5running, top5walking} = this.state;
        let item:any;
        let finish:any;
        if(dandiAll){
            const items = dandiAll.filter((data)=>{
                if(this.state.searchAll == null)
                    return data
                else if(data.first_name.toLowerCase().includes(searchAll.toLowerCase()) || data.last_name.toLowerCase().includes(searchAll.toLowerCase()) || 
                        data.rank.toString().includes(searchAll.toLowerCase()) || data.distance.toLowerCase().includes(searchAll.toLowerCase())){
                    return data
                }
              }).map((data,index)=>{
                    return(
                        <li key={index}>
                          <p>
                              <span className="span_number">{data.rank}. </span>
                              <span className="span_name">
                                  <a>{data.first_name+' '+data.last_name}</a>
                              </span>
                              {(userId == data.user_id  && data.show_ceri!=0)?
                                <span className="rank_in certntn_dandi">
                                    <a href={data.cer_link} target='blank'>Certificate</a>  
                                    {/* {data.distance} */}
                                 </span>
                                 :
                                //  <span className="rank_in certntn_dandi disable_Certi">
                                //      <a href="#" >Finisher</a>
                                //  </span>
                                <span className="rank_in">{data.distance}</span>
                              }
                             
                          </p>
                      </li>
                      )
              })
              item = items;
        }
        if(dandiFinisher){
            const items = dandiFinisher.filter((data)=>{
                if(this.state.searchFinisher == null)
                    return data
                else if(data.first_name.toLowerCase().includes(searchFinisher.toLowerCase()) || data.last_name.toLowerCase().includes(searchFinisher.toLowerCase()) || 
                        data.rank.toString().includes(searchFinisher.toLowerCase()) || data.distance.toLowerCase().includes(searchFinisher.toLowerCase())){
                    return data
                }
              }).map((data,index)=>{
                    return(
                        <li key={index}>
                          <p>
                              <span className="span_number">{data.rank}. </span>
                              <span className="span_name">
                                  <a>{data.first_name+' '+data.last_name}</a>
                              </span>
                              { (userId == data.user_id) ?
                                <span className="rank_in certntn_dandi">
                                    <a href={data.cer_link} target='blank'>Certificate</a>  
                                    {/* {data.distance} */}
                                 </span>
                                 :
                                 <span className="rank_in certntn_dandi disable_Certi">
                                     <a href="#" >Finisher</a>
                                 </span>
                              }
                          </p>
                      </li>
                      )
              })
              finish = items;
        }
        return(
            <div className='container leader_main navratri_main'>
                 <ToastContainer />
                 <a href="#" className="btn stage_btn" id='showCertificateModel'
                    data-toggle="modal" data-target="#modal-fullscreen" style={{ display: 'none' }}></a>
                <div className='dandi_img navratri_logo_in'>
                <img src="./Images/navratri_bg.jpg" />
                </div>
                 <div className="row">
                    <div className="col-md-12">
                        <div className="leader_title">
                            <div className="heading">
                                <h3>Navratri Run & Ride Challenge</h3>
                                <p>Certificate will avilable on 15th October 2021</p>
                            </div>
                            <div className="sub_title">
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className='leader_content'>
                    <div className='row'>
                        <div className='offset-md-2 col-md-8'>
                        <div className="ranking_box">
                            <div className="rank_title first_2">
                                    <div className="title_inner">
                                        <div className="rank_title_name dandip_title">
                                            <h3>Participant</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ranking_list_dandi">
                                    <div className="top_five_list all_runner_data" data-year="2020">
                                        <input type="text" name="searchAll" id="keyword" className="form-control" placeholder="Search Your Name And Get Certificate"
                                            style={{ textAlign: 'center', marginBottom: 10 }} onChange={this.searchSpace}/>

                                        <ul className="unordered-list text-left leadershipboard" id="lsboard">
                                                {dandiAll !=''
                                                ?item:
                                                <li className="no_data">
                                                    No data available
                                                </li>
                                               }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                    </div>
                </div>
    {/* dandi certificate */}
                <MediaQuery minDeviceWidth={1224} >
                    <div className="modal fade modal-fullscreen" id="modal-fullscreen" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered  fullscreenModel">
                            <CertificateView  Link={link} dandi={1}/>
                        </div>
                    </div>
                </MediaQuery>

                                             

                <div className='row'>
                        {/* Top 3 Men Runner */}
                        <div className="col-12 col-md-12">
                               <div className="heading-block running-heading">
                                   <div className="heading-icon">
                                       <img src="./Images/cyruns_runner.svg" />
                                       </div>
                                    <h3>Running</h3>
                                </div>                
                        </div>    

                        <div className="col-12 col-md-6">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    
                                    <div className="ranking_box">
                                        <div className="rank_title first_4">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Top 3 <span className="t5_type"></span> Men Runners</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_cyc">
                                                <ul>
                                                {top5manRunner != '' && top5manRunner != null ?
                                                        top5manRunner.map((man, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{man.first_name + ' ' + man.last_name}</span>
                                                                    <span className="rank_in">{man.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ""}


                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "cyruns_runner.svg", title: "Men Runner" }) ; this.viewAll(1,1) }}>
                                                    View Top 100 Men Runner List</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Top 3 Women Runner */}
                        <div className="col-12 col-md-6">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    
                                    <div className="ranking_box">
                                        <div className="rank_title first_5">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                <h3>Top 3 <span className="t5_type"></span> Women Runners</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_run">
                                                <ul>
                                                    {top5womanRunner != '' && top5womanRunner != null ?
                                                        top5womanRunner.map((woman, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{woman.first_name + ' ' + woman.last_name}</span>
                                                                    <span className="rank_in">{woman.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ""}

                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "cyruns_runner.svg", title: "Women Runners" });this.viewAll(2,1) }}>
                                                    View Top 100 Women Runner List</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Walking */}
                      
                    </div>   


                    <div className='row'>
                        {/* Top 3 Men Runner */}
                        <div className="col-12 col-md-12">
                               <div className="heading-block running-heading">
                                   <div className="heading-icon">
                                       <img src="./Images/cyruns_cyclist.svg" />
                                       </div>
                                    <h3>Cyclist</h3>
                                </div>                
                        </div>    

                        <div className="col-12 col-md-6">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    
                                    <div className="ranking_box">
                                        <div className="rank_title first_4">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Top 3 Men <span className="t5_type"></span> Cyclist </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_cyc">
                                                <ul>
                                                {top5manCyclist != '' && top5manCyclist != null ?
                                                        top5manCyclist.map((man, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{man.first_name + ' ' + man.last_name}</span>
                                                                    <span className="rank_in">{man.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ""}


                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "cyruns_cyclist.svg", title: "Men Cyclist" }) ; this.viewAll(1,2) }}>
                                                    View Top 100 Men Cyclist List</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Top 3 Women Runner */}
                        <div className="col-12 col-md-6">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    
                                    <div className="ranking_box">
                                        <div className="rank_title first_5">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                <h3>Top 3 Women <span className="t5_type"></span> Cyclist </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_run">
                                                <ul>
                                                    {top5womanCyclist != '' && top5womanCyclist != null ?
                                                        top5womanCyclist.map((woman, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{woman.first_name + ' ' + woman.last_name}</span>
                                                                    <span className="rank_in">{woman.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ""}

                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "cyruns_cyclist.svg", title: "Women Cyclist" });this.viewAll(2,2) }}>
                                                    View Top 100 Women Cyclist List</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Walking */}
                      
                    </div>                                


                {/* AllRanklistModel */}
            <div className="modal fade show" id="allRanklist" role="dialog" >
             <div className="modal-dialog modal-dialog-centered" >
                 <AllRanklistModel icon={this.state.icon} title={this.state.title}
                     alldata={this.state.Vadata} />
             </div>
            </div>



            </div>
            
        )
    }
}

export default DandiDadhboard;