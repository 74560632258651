import * as React from 'react';
import axios from 'axios';
import { authStack, appStack } from './Routs';
import If from '../../components/If';
import '../../styles/Home.css';
import '../../styles/Dashboard.css';
import ScreenHeader from '../../components/ScreenHeader';
import FindFriend from '../../components/FindFriend';
import Loader from 'react-loader-spinner'
import cookie from 'react-cookies';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect} from 'react-router-dom';

interface Props {
    history: any;
    location: any;
}
interface State {
    isLoggedIn: boolean;
    isLoading: boolean;
}


class Navigator extends React.Component<Props, State>{
    authStack = authStack();
    appStack = appStack();
    constructor(props: any) {
        super(props);
        this.state = {
            isLoggedIn: false,
            isLoading: true,
        };
    }
    componentDidMount() {
       // const User: any = sessionStorage.getItem('User');
      // const {search} = this.props.location;
      // console.log(this.props.location);
        const User:any = cookie.load('User');
        if (User) {
            this.setState({isLoggedIn: true });
        }else{
           
        }
        this.setState({ isLoading: false });
      
    }


    render() {
        const { isLoggedIn, isLoading } = this.state;
        return (
            <div>
                {/* <ToastContainer/> */}
                {
                    isLoading ?
                        <div className='screen_loader'>
                            <Loader
                                type="Bars"
                                color="#fc5200"
                                height={100}
                                width={100}
                            />
                        </div>
                        :
                        <div>
                            <If show={isLoggedIn}>
                                <ScreenHeader history={this.props.history} />
                                <div className='authcreen_main' style={{ marginTop: 70 }}>
                                    {this.appStack}
                                </div>
                                <div className="dsh_find_fri">
                                    <div className="modal fade" id="findFriend" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                        <FindFriend />
                                    </div>
                                </div>
                            </If>
                            <If show={!isLoggedIn}>
                                {this.authStack}
                            </If>
                        </div>
                }

            </div>

        );
    }
}

export default Navigator;