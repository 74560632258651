import * as React from 'react';
import axios from 'axios';

import {AsyncStorageService} from '../services/AsyncStorage';
import Avatar from 'react-avatar';
import {UserData} from '../services/UserData';
interface Props {

}
interface State {
    myActivity:any;
}
class HomeUserDetails extends React.Component<Props,State>{
    constructor(props:any){
        super(props);
        this.state = {
            myActivity:[]
        };
        
    }
    async componentDidMount(){
        const user =  await AsyncStorageService.getUser();
        const userId = user.user_id;

        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
        let formData = new FormData();
        formData.append('user_id',userId);
        await axios.post("https://api.cyruns.in/api/athletes.php?action=myactivity", formData,init)
        .then(response => {
            if(response.data.msgcode == 0){
               this.setState({myActivity: response.data.myactivity});
            }
            
        })
      }
    render() {
        const {myActivity} = this.state;
        return (
            <div className='usrd_main'>
                <div className='usrdtl_fsection'>
                    <div className="usrdtl_img_ico">
                       <a href="/profile">
                           {myActivity.photo!=''?
                           <img src={myActivity.photo}/>:
                           <Avatar name={myActivity.name} size='50px' round={true} color='#33691e' style={{fontWeight:600}}/>
                           }
                       </a> 
                    </div>
                    <div className='usrdtl_usrname'>
                        <a href="/profile"><p>{myActivity.name}</p></a>
                    </div>
                    <div className='usdtl_sfollow'>
                        <div className='usdfollowng'>
                            <a href="">
                                <span>Following</span>
                                <b>{myActivity.following?myActivity.following:0}</b>
                            </a>
                        </div>
                        <div className='usdfollowng'>
                            <a href="">
                                <span>Followers</span>
                                <b>{myActivity.followers?myActivity.followers:0}</b>
                            </a>
                        </div>
                        <div className='usdfollowng'>
                            <a href="/my-activity">
                                <span>My Activities</span>
                                <b>{myActivity.activity?myActivity.activity.length:0}</b>
                            </a>
                        </div>
                    </div>

                    <div className='usdet_latestact'>
                        <p>Latest Activity</p>
                        <div>
                            <b>Afternoon Run</b><p>October 16, 2020</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default HomeUserDetails;