import * as React from 'react';
import cryptoJSON from 'crypto-json';
import cookie from 'react-cookies';


export class AsyncStorageService {
    private static readonly USER = 'user';
  
    static async getUser() {
      try {
       // const response:any = await sessionStorage.getItem('User');
        const User:any = cookie.load('User');
       // const decryptedData = cryptoJSON.decrypt(User,'monkdesk@2019');
        return (User);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    static clearUser() {
    //  return sessionStorage.clear();
      return  cookie.remove('User', { path: '/' });
    }
  }
  