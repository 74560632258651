import * as React from 'react';
import axios from 'axios';
import FilterResults from 'react-filter-search';
import {AsyncStorageService} from '../services/AsyncStorage';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'muicss/lib/react/button';
import Avatar from 'react-avatar';

import If from './If';

interface Props {

}
interface State {
  userList: any;
  value: any;
  loader:any;
  hovId:any;
  userId:any;
}
class FindFriend extends React.Component<Props, State>{
   init:any = {
    method: 'POST',
    headers: { 'content-type': 'multipart/form-data',
                'Accept': 'application/json'},
    mode: 'cors',
    cache: 'default',
    dataType: 'json',
    async:    true,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      userList: [],
      value: '',
      loader:false,
      hovId:'',
      userId:''
    };
  }
 async componentDidMount() {
    const user =  await AsyncStorageService.getUser();
    const userId = user.user_id;
    this.setState({userId});
    
   
  let formData = new FormData();
  formData.append('user_id',userId);
  await axios.post("https://api.cyruns.in/api/athletes.php?action=userlist", formData,this.init)
    .then(response => {
       // console.log(response.data);
      this.setState({loader:false});
      if(response.data.msgcode == 0){
        this.setState({userList:response.data.user_detail});
      }
      else{
          toast.error(response.data.msg);
      }
    })
    .catch(error => {        
          this.setState({loader:false});
          console.log(error);
     });
  }
 async FollowNUnfollw(type,id){
      const {userId} = this.state;
      
      let formData = new FormData();
      formData.append('user_id',userId);
      formData.append('follow_type',type);
      formData.append('follow_id',id);
      await axios.post("https://api.cyruns.in/api/athletes.php?action=follow_unfollow", formData,this.init)
        .then(response => {
           // console.log(response.data);
          this.setState({loader:false});
          if(response.data.msgcode == 0){
            toast.success(response.data.msg);
            this.componentDidMount();
          }
          else{
              toast.error(response.data.msg);
          }
        })
        .catch(error => {
            const Msg='Something went to wrong please Try later... OR Check your internet connection';
              toast.error(Msg);
              this.setState({loader:false});
              console.log(error);
         });

  }
  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };

  render() {
    const { userList, value,hovId } = this.state;

    return (
      <div className="modal-dialog userlist_model modal-dialog-centered" role="document">
        <ToastContainer/>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Find Friends</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className='findfri_ser_inpt'>
              <input type="text" className="form-control" id="sera_frd" placeholder="Search..."
                value={value} onChange={this.handleChange} />
              <div>
                <i className="fa fa-search" aria-hidden="true"></i>
              </div>
            </div>
            <div className="ffrind_result">
              {/* {value!=''? */}
              <FilterResults
              value={value}
              data={userList}
              renderResults={results => (
                <div>
                 {results.map((user,index)=>{
                   return <div key={index} className="usl_box">
                            <div className='ul_img'>
                              {user.user_photo!=''?
                               <img src={user.user_photo} />:
                               <Avatar name={user.user_name} size='40px' round={true} color='#ca7245' style={{fontWeight:600}}/>
                              }
                            </div>
                            <div className='ul_uname'>
                              <a href="#">{user.user_name}</a>
                              <p>{user.city!=''?user.city+',':""}
                                 {user.state!=''?user.state+',':''} {user.country}</p>
                            </div>
                            <div className='ul_flbtns'>
                              {user.follow?
                               <Button className='unflw_btn'  onMouseEnter={() => this.setState({hovId:user.user_id})}
                                 onMouseLeave={()=>this.setState({hovId:''})}
                                 onClick={()=>this.FollowNUnfollw('unfollow',user.follow_id)}>
                                 {hovId==user.user_id?'UNFOLLOW':'FOLLOWING'}
                                </Button>:
                               <Button className='flwbtn' onClick={()=>
                                this.FollowNUnfollw('follow',user.user_id)}
                                >Follow</Button>
                              }
                            </div>
                         </div>
                 })}
                </div>
              )}
            />
             {/* :"" } */}
              

            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
          </div>
        </div>
      </div>
      // <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
      //  Launch demo modal
      // </button>

    );
  }
}

export default FindFriend;