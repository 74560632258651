import * as React from 'react';

import $ from 'jquery';
import axios from 'axios';
import {AsyncStorageService} from '../services/AsyncStorage';

interface Props {

}

interface State {
    myActivity:any;
}
class MyActivity extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
            myActivity:[]
        }
        
    }
     componentDidMount=async()=>{
        const user =  await AsyncStorageService.getUser();
        const userId = user.user_id;

        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
        let formData = new FormData();
        formData.append('user_id',userId);
        await axios.post("https://api.cyruns.in/api/athletes.php?action=myactivity", formData,init)
        .then(response => {
            if(response.data.msgcode == 0){
               this.setState({myActivity: response.data.myactivity});
            }
            
        })
    }

    render() {
        $(document).ready(function () {
            ($('#myactivityTable') as any).DataTable();
        });
        const {myActivity} = this.state;
       
        const activity = myActivity.activity;
        console.log(activity);
        return (
            <div className='container'>
                <div className='myactivity_main'>
                    <div>
                        <h3>My Activity</h3>
                    </div>
                    <div className='table-responsive'>
                        {activity!='' && activity!=null ?
                         <table className="table table-bordered table-hover myactivity_table" id="myactivityTable"
                         style={{ width: '100%' }} cellSpacing={0}>
                         <thead>
                             <tr className='data_tbl_row assignrow'>
                                 <th>SR NO</th>
                                 <th>Sport</th>
                                 <th>Date</th>
                                 <th>Title</th>
                                 <th>Time</th>
                                 <th>Duration</th>
                                 <th>Action</th>
                             </tr>
                         </thead>
                         <tfoot>
                             <tr className='data_tbl_row'>
                                 <th>SR NO</th>
                                 <th>Sport</th>
                                 <th>Date</th>
                                 <th>Title</th>
                                 <th>Duration</th>
                                 <th>Distance</th>
                                 <th>Action</th>
                             </tr>
                         </tfoot>

                         <tbody className='user_list_tbody'>
                             {activity.map((act,i)=>{
                            return  <tr key={i}>
                                        <td>{i+1}</td>
                                        <td></td>
                                        <td>{act.work_date}</td>
                                        <td>{act.post_title}</td>
                                        <td>{act.duration}</td>
                                        <td>{act.distance} km</td>
                                        <td></td>
                                    </tr>
                             })}
                         </tbody>
                     </table>

                      :"No Data Found" }
                       
                    </div>
                </div>
            </div>
        )
    }
}

export default MyActivity;