import * as React from 'react';
import Iframe from 'react-iframe';
import {Animated} from "react-animated-css";

interface Props {
    stageNo?:any;
    Link:any;
    Month?:any;
    dandi?:any;
}
interface State {

}

class CertificateView extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const {stageNo,Link,Month} = this.props;
        return (
            <div className="modal-content fullscreenModelContent">
                <div className="modal-header certModelHeader">
                    <img src='./Images/goal.png' />
                    <h4 className="modal-title" id="myModalLabel">
                        {Month!=''?
                           Month +'- certificate':
                           'Stage -' + stageNo +' certificate' 
                        }
                    </h4>
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                </div>
                <Animated animationIn="rotateInDownLeft" animationOut="rotateOutDownRight" animationInDuration={2000} animationOutDuration={1000} isVisible={true}>
                <div className="modal-body certModelBody" style={{height:'90vh'}}>
                    <Iframe 
                        url={Link}
                        width="100%"
                        height="100%"
                        id="myId"
                        className="myClassname"
                        display="block"
                        position="relative"
                        styles={{padding: '10px'}}
                        frameBorder={0}
                        allow="fullscreen"
                    />
                    {/* <iframe src={Link} width = "100%" height = "100%" seamless>
                    </iframe> */}
                </div>
                </Animated>
                {/* <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div> */}
            </div>
        )
    }
}

export default CertificateView;