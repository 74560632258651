import * as React from 'react';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';

import SocialPost from '../components/SocialPost';
import HomeUserDetails from '../components/HomeUserDetails';
import ScreenHeader from '../components/ScreenHeader';
import {AsyncStorageService} from '../services/AsyncStorage';

import ReactImageVideoLightbox from 'react-image-video-lightbox';

interface Props {
    history: any;
    location: any;

}
interface State {
    imgArr:any;
    lightboxOpen:boolean;
}

class Dashboard extends React.Component<Props, State>{

    constructor(props: any) {
        super(props);
        this.state = {
            imgArr:[],
            lightboxOpen: false
        }
    }
    async   componentDidMount(){
        const user = await AsyncStorageService.getUser();
        const Msg = this.props.location.state;
        if(Msg){
             toast.success(Msg);
        }
    }
    selectItemRow(data){
        this.setState({lightboxOpen:data.lightboxOpen,imgArr:data.imgArr});
    }

    render() {
       // console.log(this.props.location.state);
        const {imgArr} =this.state;
        return (
            <div className="dashbrd_main">
                <ToastContainer/>
                <div className='dash_contain'>
              
                    <div className="container">
                  
                        <div className="row">
                            <div className="col-md-3 col-sm-3" >
                                <HomeUserDetails />
                            </div>
                            <div className="col-md-6 col-sm-6" >
                                <SocialPost selectItem={this.selectItemRow.bind(this)}/>
                            </div>
                            <div className="col-md-3 col-sm-3" >
                                Other
                            </div>
                        </div>
                        {
                    this.state.lightboxOpen &&
                    <div className='img-lightbox'>
                     <ReactImageVideoLightbox
                            data={imgArr}
                            startIndex={0}
                            showResourceCount={true}
                            onCloseCallback={() => { this.setState({ lightboxOpen: false }); }}
                        />
                    </div>
                    
                    }
                      
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Dashboard;