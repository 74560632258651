import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert'; // Import
import WorkoutViewModel from '../components/WorkoutViewModel';
import axios from 'axios';
import { AsyncStorageService } from '../services/AsyncStorage';
import { ToastContainer, toast } from 'react-toastify';

interface Props {
    history:any;
}
interface State {
    [x: number]: any;
    yearList: any;
    monthList: any;
    month: any;
    year: any;
    type: any;
    currentYear: any;
    All: any;
    workOut: any;
}

class MyworkoutRecord extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
            yearList: [],
            monthList: [],
            month: '',
            year: '',
            currentYear: '',
            type: '',
            All: [],
            workOut: {}

        }
    }
   
    yearNmonth() {
        var currentYear = (new Date()).getFullYear();
        var currentMonth = (new Date()).getMonth() + 1;
        const yearList: any = []
        for (var i = 2010; i <= currentYear; i++) {
            yearList.push(i);
        }
        const monthList = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        this.setState({ yearList, monthList, currentYear, year: currentYear, month: currentMonth });
        //this.resateState();
    }

    myWorkoutall = async () => {
        const user = await AsyncStorageService.getUser();
        const userId = user.user_id;
        const { type, month, year } = this.state;
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();
        formData.append('user_id', userId);
        formData.append('type', type);
        formData.append('year', year);
        formData.append('month', month);
        await axios.post("https://api.cyruns.in/api/athletes.php?action=myworkoutall", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    this.setState({ All: response.data.myworkoutall });
                } else {
                    this.setState({ All: response.data.myworkoutall });
                    //toast.error(response.data.msg);
                }
            })
    }
    componentDidMount() {
        this.yearNmonth();
        this.myWorkoutall();
    }
    onInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    onConfirmDialog(work) {
        confirmAlert({
            title: 'Are you sure ?.',
            message: 'You want to delete this record',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteRecord(work)
                },
                {
                    label: 'No',
                    onClick: () => console.log('Click No')
                }
            ]
        });
    }
    deleteRecord=async(work)=>{
       const postID = work.post_id;
       const user = await AsyncStorageService.getUser();
       const userId = user.user_id;
       let init: any = {
        method: 'POST',
        headers: {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        mode: 'cors',
        cache: 'default',
        dataType: 'json',
        async: true,
    };
    let formData = new FormData();
    formData.append('user_id', userId);
    formData.append('post_id', postID);

    await axios.post("https://api.cyruns.in/api/athletes.php?action=deleteworkout", formData, init)
        .then(response => {
            if (response.data.msgcode == 0) {
                toast.success(response.data.msg);
                this.myWorkoutall();
            } else {
                toast.error(response.data.msg);
            }
        })
    }
    goEditWorkout(data){
        this.props.history.push({
            pathname:"/editworkout",
            state: data
        })
    }
    render() {
        const { monthList, yearList, year, month, currentYear, All } = this.state;
        return (
            <div className="col-md-12">
                <ToastContainer />
                <div className="table_running col-12 col-md-6">
                    <div className="">
                        <div className='workdata-filter'>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Filter By :</span>
                                </div>
                                <select className="form-control filter-rs" id="custom_filter[return_duration]"
                                    name='month' onChange={this.onInputChange}>
                                    <option value="">Month</option>
                                    {monthList != null && monthList != '' ?
                                        monthList.map((Month, i) => {
                                            return <option value={i + 1} key={i}
                                                selected={month == i + 1 ? true : false}>{Month}</option>
                                        })
                                        :
                                        <option value="">Nos.</option>
                                    }
                                </select>
                                <select className="form-control filter-rs" id="custom_filter[return_duration]"
                                    name='type' onChange={this.onInputChange}>
                                    <option value="">All</option>
                                    <option value="1">Running</option>
                                    <option value="2">Cycling</option>
                                    <option value="3">Walking</option>
                                    <option value="4">Swimming</option>
                                    <option value="5">Stairs</option>
                                    <option value="6">Steps</option>
                                    <option value="7">Exercise</option>
                                </select>
                                <select className="form-control filter-rs" id="custom_filter[return_duration]"
                                    name='year' onChange={this.onInputChange}>
                                    <option value="">Year</option>
                                    {yearList != null && yearList != '' ?
                                        yearList.map((Year, i) => {
                                            return <option value={Year} key={i}
                                                selected={Year == year ? true : false}>{Year}</option>
                                        })
                                        :
                                        <option value="">Nos.</option>
                                    }
                                </select>
                                <button type="button" className="btn btn-secondary searchwork-btn"
                                    onClick={this.myWorkoutall}>
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6'>

                </div>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <td><b>#</b></td>
                                <td><b>Date</b></td>
                                <td><b>Duration</b></td>
                                <td><b>Distance(KM)</b></td>
                                <td><b>Sport(Type)</b></td>
                                <td><b>Timed Event</b></td>
                                <td><b>Link</b></td>
                                <td><b>Added from</b></td>
                                <td><b>Action</b></td>
                            </tr>
                        </thead>
                        {All != '' ?
                            <tbody>
                                {All.map((all, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{all.work_date}</td>
                                        <td>{all.duration}</td>
                                        <td>{all.distance}</td>
                                        <td>{all.w_type == 'Stairs'?'Stairs('+all.stairs+')':
                                       all.w_type == 'Steps'?'Steps('+all.steps+')':all.w_type}</td>
                                        <td>{all.is_time_event ?
                                            <i className="fa fa-check tevent_ico" aria-hidden="true"></i>
                                            : "-"}
                                        </td>
                                        <td className='w_link'>
                                            {all.w_link != "" ?
                                                <a href={all.w_link} target="_blank">
                                                    <i className="fa fa-link" title="Link"></i>
                                                </a> :
                                                "-"
                                            }

                                        </td>
                                        <td>
                                            {all.added_from == "strava" ?
                                                <p className='strava_Added'>Strava</p>
                                                :
                                                <p className='strava_Added web_Added'>Web</p>
                                            }

                                        </td>
                                        <td className='workrecord-action'>
                                            <a href="#" data-tip data-for='viewDetail' data-toggle="modal" data-target="#workoutViewModel"
                                                onClick={() => { this.setState({ workOut: all }) }}>
                                                <i className="fa fa-eye"></i>
                                            </a>
                                            <ReactTooltip id='viewDetail' type='info' >
                                                <span>view Detail</span>
                                            </ReactTooltip>
                                            {all.added_from != "strava" ?
                                            <a data-tip data-for='editDetail' onClick={()=>this.goEditWorkout(all)}>
                                                <i className="fa fa-edit" title="Edit Details"></i>
                                            </a>
                                            :"--------"}
                                            <ReactTooltip id='editDetail' type='warning' >
                                                <span>Edit Workout</span>
                                            </ReactTooltip>
                                            <a data-tip data-for='deleteDetail' onClick={()=>this.onConfirmDialog(all)}>
                                                <i className="fa fa-trash-o" aria-hidden="true" title="Delete"></i>
                                            </a>
                                            <ReactTooltip id='deleteDetail' type='error' >
                                                <span>Delete Workout</span>
                                            </ReactTooltip>
                                        </td>
                                    </tr>
                                })}


                            </tbody>
                            :
                            <tbody className="t5_block" id="dt_filter">
                                <tr>
                                    <td colSpan={9}>No Data Found...!</td>
                                </tr>
                            </tbody>
                        }

                    </table>
                </div>

                {/* WorkoutViewModel */}
                <div className="modal fade show" id="workoutViewModel" role="dialog" >
                    <div className="modal-dialog modal-dialog-centered modal-lg" >
                        <WorkoutViewModel workOut={this.state.workOut} />
                    </div>
                </div>
            </div>


        )
    }
}

export default MyworkoutRecord;