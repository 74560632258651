import * as React from 'react';
import axios from 'axios';

import Login from './Login';
import Signup from './Signup';
import HomeSlider from '../components/HomeSlider';
import '../styles/Home.css';
import Input from 'muicss/lib/react/input';
import { ToastContainer, toast } from 'react-toastify';
import cookie from 'react-cookies';

interface Props {
    history: any;
    location:any;
}
interface State {
    [x: number]: any;
    LoginShow: boolean;
    f_email: any;
}
class Home extends React.Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            LoginShow: true,
            f_email:''
        };
    }
    componentDidMount=async()=>{
        const {search} = this.props.location;
        if(search){
            const userId = search.split("=")[1];
           // console.log(userId);
            let init:any = {
                method: 'POST',
                headers: { 'content-type': 'multipart/form-data',
                            'Accept': 'application/json'},
                mode: 'cors',
                cache: 'default',
                dataType: 'json',
                async:    true,
              };
             
            let formData = new FormData();
            formData.append('user_id',userId);
        
        await axios.post("https://api.cyruns.in/api/athletes.php?action=connect_user", formData,init)
            .then(response => {
                if(response.data.msgcode == 0){
                    //toast.success(response.data.msg);
                    cookie.save('User',response.data.user, { path: '/' , maxAge: 43200});
                    this.props.history.push({ 
                        pathname: '/my-workout',
                        state: response.data.msg
                       });
                       window.location.reload();
                }else{
                    toast.error(response.data.msg);
                }
            })
        }
     }
    ScreenShow() {
        this.setState({ LoginShow: !this.state.LoginShow });
    }
    onInputChange(e: any) {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({[name]: value});
    }
    forgotPassword=async()=>{
        const {f_email} = this.state;
        console.log(f_email);
        if(f_email != ''){
            let init:any = {
                method: 'POST',
                headers: { 'content-type': 'multipart/form-data',
                            'Accept': 'application/json'},
                mode: 'cors',
                cache: 'default',
                dataType: 'json',
                async:    true,
              };
             
            let formData = new FormData();
            formData.append('email',f_email);
        
        await axios.post("https://api.cyruns.in/api/athletes.php?action=forgotpassword", formData,init)
            .then(response => {
                if(response.data.msgcode == 0){
                    toast.success(response.data.msg);
                }else{
                    toast.error(response.data.msg);
                }
            })
        }else{
            toast.error('please enter email first');
        }
    }
    render() {
        const { LoginShow } = this.state;
        return (
            <div className="home_screen">
                <ToastContainer/>
                <header className='home_header'>
                    <div className='home_header_logo'>
                        <a href=""> <img src='../Images/cyruns-logo.png' /></a>
                    </div>
                </header>
                <div>
                    <div className="container">
                        <div className="row home_con_main">
                            <div className="col-md-6 col-sm-12" >
                                <div className='home_headline'>
                                    <p>CYRUNS Athletes</p>
                                </div>
                                <HomeSlider />
                            </div>

                            <div className="col-md-6 col-sm-12" >
                                {LoginShow ?
                                    <Login history={this.props.history} /> :
                                    <Signup history={this.props.history} />
                                }
                                <div className='home_footer'>
                                    {LoginShow ?
                                        <div className='foot_text_home'>
                                            <span>you don't have an account? <p onClick={this.ScreenShow.bind(this)}>Signup</p></span>
                                        </div> :
                                        <div className='foot_text_home'>
                                            <p>
                                                By signing up for cyruns athletes, you agree to the<a href='#'> Terms of Service</a>. View our <a href='#'>Privacy Policy</a>.
                                        </p>
                                            <span >Already a Member? <p onClick={this.ScreenShow.bind(this)}>Log In</p></span>
                                        </div>
                                    }
                                    <span>Helpline Contact <br/> <i className="fa fa-phone"/> : 63515 80588 <br/>
                                    <i className="fa fa-envelope"/> :  support@cyruns.com
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* forgotPasswordModel */}
                    <div className="modal fade show" id="forgotPasswordModel" role="dialog" >
                        <div className="modal-dialog modal-dialog-centered" >
                            <div className="modal-content">
                                <div className="modal-header viewDetailheader">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Forgot Password</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <p>Enter email address to request instruction on how to reset your password</p>
                                    </div>
                                    <div>
                                        <div className="form-group">
                                            <Input className='log_input' name="f_email" label="Email" type='text' floatingLabel={true} onChange={(e: any) => this.onInputChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer log_btn">
                                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">CLOSE</button> */}
                                    <button type="button" className="btn btn-primary" onClick={this.forgotPassword}>SEND REQUEST</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );

    }
}

export default Home;