import * as React from 'react';
import axios from 'axios';

import '../styles/createPost.css'
import Button from 'muicss/lib/react/button';
import {AsyncStorageService} from '../services/AsyncStorage';
import { ToastContainer, toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import $ from 'jquery';
import DatePicker from 'react-date-picker';
import Switch from "react-switch";

import Loader from 'react-loader-spinner';

interface Props {
    history:any;
    location:any;
}
interface State {
    [x: number]: any;
    loader:boolean;
    distance: any;
    distanceType:any;
    hr:any;
    min:any;
    sec:any;
    elevation:any;
    elevationType:any;
    type:any;
    date:any;
    time:any;
    title:any;
    description:any;
    userId:any;
    link:any;
    Photo:any;
    isTimedEvent:boolean;
    cyruns_event:any;
    bib_no:any;
    comments:any;
    post_media:any;
    postID:any;
    msgcode:any;
    stairs:any;
    steps:any;
}
class EditWorkout extends React.Component<Props, State> {
dropzoneRef:any = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loader:false,
            distance: '',
            distanceType:'km',
            hr:'00',
            min:'',
            sec:'00',
            elevation:'',
            elevationType:'mtr',
            type:'',
            date:'',
            time:'',
            title:'',
            description:'',
            userId:'',
            link:'',
            Photo:null,
            isTimedEvent: false,
            cyruns_event:'',
            bib_no:'',
            comments:'',
            post_media:'',
            postID:'',
            msgcode:'',
            stairs:0,
            steps:0
        }
    }
    async componentDidMount(){
        const user =  await AsyncStorageService.getUser();
        this.setState({userId:user.user_id});

      
        const record = this.props.location.state;
        this.setState({
            distance:record.distance,
            hr:record.duration.split(':')[0],
            min:record.duration.split(':')[1],
            sec:record.duration.split(':')[2],
            date:new Date(record.work_date),
            type:record.w_type,
            title:record.post_title,
            link:record.w_link,
            description:record.post_desc,
            post_media:record.post_media,
            isTimedEvent:record.is_time_event,
            cyruns_event:record.event_name,
            bib_no:record.bib_no,
            comments:record.comments,
            postID:record.post_id,
            stairs:record.stairs,
            steps:record.steps
        })

        var myDate = new Date();
        var hrs = myDate.getHours();
        var title;
        if (hrs < 12)
             title = 'Morning Activity';
        else if (hrs >= 12 && hrs <= 17)
             title = 'Afternoon Activity';
        else if (hrs >= 17 && hrs <= 24)
             title = 'Evening Activity';
             if(record.post_title  == ""){
                this.setState({title});
             }
      }
    onInputChange=(e: any)=> {
        const { name, value } = e.target;
        this.setState({[name]: value});
    }

    async addManual(e){
        e.preventDefault();
        const {title,distance,distanceType,description,elevation,type,
            elevationType,hr,min,sec,time,date,userId,link,Photo,isTimedEvent,
            cyruns_event,bib_no,comments,postID,post_media,stairs,steps
        } = this.state;
         
    if(min!=''  && 
    date!='' && date!=null  && type!='' && title!=''){     
       
        const newdate =  date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
      
        let Distance:any;
        let Elevation:any;
        let istimedevent:any;
        if(distanceType === 'miles'){
             Distance = (distance)*(1609);
        }
        else if(distanceType === 'km'){
             Distance = (distance)*(1000);
        }
        else{
             Distance = distance;
        }
        if(elevationType == 'ft'){
          var ElevationValue:any = (elevation)/3.280;
          Elevation = parseFloat(ElevationValue).toFixed(2); 
        }else{
            Elevation = elevation;
        }
        if(isTimedEvent){
            istimedevent = 1
        }else{
            istimedevent = 0
        }
       

        const timeDuration = hr+':'+min+':'+sec;
        let init:any = {
          method: 'POST',
          headers: { 'content-type': 'multipart/form-data',
                      'Accept': 'application/json'},
          mode: 'cors',
          cache: 'default',
          dataType: 'json',
          async:    true,
        };
       
      let formData = new FormData();
      formData.append('user_id',userId);
      formData.append('post_id',postID);
      formData.append('post_type','workout');
      formData.append('post_title',title);
      formData.append('post_desc',description);
      formData.append('distance',Distance);
      formData.append('duration',timeDuration);
      formData.append('w_type',type);
      formData.append('work_date',newdate);
      formData.append('link',link);
      formData.append('post_media',Photo);

      formData.append('is_time_event',istimedevent);
      formData.append('cyruns_event',cyruns_event);
      formData.append('bib_no',bib_no);
      formData.append('comments',comments);
      formData.append('stairs',stairs);
      formData.append('steps',steps);
      
if(hr<=24 && min <= 59 && sec <=59){  
      if(Distance>10000){
        if(link!='' || Photo!=null || post_media != ''){
            if(istimedevent){
                if(cyruns_event!='' && bib_no!=''){
                    this.editWorkout(formData,init);
                }else{
                   toast.error("Event name and BIB No. both are required");
                }
              
            }
            else{
                this.editWorkout(formData,init);
            }
        }else{
            toast.error('link or screenshort is required for more than 10 km');
            this.setState({msgcode:'l'});
            this.resateMsgcode();
        }
      
    } else{
        this.editWorkout(formData,init);
    }
    
} else{
    toast.error('Dutation is not valid');
    this.setState({msgcode:'d'});
    this.resateMsgcode();
}
        }else{
            toast.error('please fill all required fields');
        }  
      }

editWorkout=async(formData,init)=>{
this.setState({loader:true});
await axios.post("https://api.cyruns.in/api/athletes.php?action=editworkout", formData,init)
.then(response => {
    this.setState({loader:false,msgcode:response.data.msgcode});
        this.resateMsgcode();
    if(response.data.msgcode == 0){
        toast.success(response.data.msg);
        this.props.history.push({ 
            pathname: '/my-workout',
            state: response.data.msg
            });
            window.location.reload();
    }
    else{
        toast.error(response.data.msg);
    }
    })
}
resateMsgcode = () => {
    setTimeout(() => {
       this.setState({msgcode:""});
      }, 5000); 
}
    getPhoto(file:any){
    if(file.length == 1){
        if(file[0].type =='image/jpeg' || file[0].type =='image/jpg' || file[0].type =='image/png'){
            this.setState({Photo: file[0],
            });
        }else{
            toast.error("upload must be an jpg/jpeg/png image");
        }
        
    }else{
        toast.error("You have to upload only 1 image!");
    }
    }
    imageRemove(){
        this.setState({Photo:null});
    }
    DatePickers=(date)=>{
        this.setState({date});
    }
    handleChange=(isTimedEvent) =>{
    this.setState({ isTimedEvent });
    }
    render() {
        const {loader,title,isTimedEvent,distance,hr,min,sec,date,type,post_media,description,
            link,cyruns_event,bib_no,comments,msgcode} = this.state;
        
        return (
            <div className="manualentry_screen ">
                <div className='container'>
                <ToastContainer/>
                
                <div className='entry_title'>
                   Edit Workout
                </div>
                <div className='enrty_inputs'>
                    <div className='row'>
                        <div className='form-group col-md-4'>
                            {/* <div>
                                <label htmlFor="distanceinput" className="">Distance<span className="required_text">*</span></label>
                                <form className="form-inline distance_form">
                                    <input type="number" className={msgcode == 2?"form-control redalert":"form-control"} id="distanceinput" placeholder="" 
                                    onChange={this.onInputChange} name='distance' value={distance} min="0"/>
                                    <select className="form-control" id="exampleSelect1"
                                    onChange={this.onInputChange} name='distanceType'>
                                        <option value='km'>kilometers</option>
                                        <option value='mtr'>meters</option>
                                        <option value='miles'>miles</option>
                                    </select>
                                </form>
                            </div> */}
                            <div>
                                <label htmlFor="sportType">Sport<span className="required_text">*</span></label>
                                <select id="sportType" className="form-control" defaultValue=''
                                onChange={this.onInputChange} name="type">
                                    <option value=''>Choose...</option>
                                    <option value="Cycling" selected={type=="Cycling"?true:false}>Cycling</option>
                                    <option value="Running" selected={type=="Running"?true:false}>Running</option>
                                    <option value="Swimming" selected={type=="Swimming"?true:false}>Swimming</option>
                                    <option value="Walking" selected={type=="Walking"?true:false}>Walking</option>
                                    <option value="Stairs" selected={type=="Stairs"?true:false}>Stairs</option>
                                    <option value="Steps" selected={type=="Steps"?true:false}>Steps</option>
                                    <option value="Exercise" selected={type=="Exercise"?true:false}>Exercise</option>
                                    </select>
                                </div>
                            </div>

                        <div className='form-group col-md-4'>
                            <label htmlFor="durationInputh" className="">Duration<span className="required_text">*</span></label>
                            <form className="form-inline duration_form">
                                <input type="number" className={msgcode == 2 ||msgcode == 'd'?"form-control redalert":"form-control"} id="durationInputh" placeholder="HH"
                                 onChange={this.onInputChange} name='hr' min="0" max="98" value={hr}/>
                                 <input type="number" className={msgcode == 2 ||msgcode == 'd'?"form-control redalert":"form-control"} id="durationInputhm" placeholder="MM"
                                  onChange={this.onInputChange} name='min' min="0" max="59" value={min}/>
                                 <input type="number" className={msgcode == 2 ||msgcode == 'd'?"form-control redalert":"form-control"} id="durationInpuths" placeholder="SS"
                                  onChange={this.onInputChange} name='sec' min="0" max="59" value={sec}/>
                            </form>
                        </div>


                        <div className='form-group col-md-4'>
                         {/* <label htmlFor="elevationInput" className="">Elevation</label>
                            <form className="form-inline distance_form">
                                <input type="number" className="form-control" id="elevationInput" placeholder="" 
                                name='elevation' onChange={this.onInputChange} />
                                <select className="form-control" id="evevationSelect" 
                                 onChange={this.onInputChange} name='elevationType'>
                                    <option value='mtr'>meters</option>
                                    <option value='ft'>feet</option>
                                </select>
                            </form> */}
                                <label htmlFor="date">Date<span className="required_text">*</span></label>
                                <div className="form-group form-inline dateNtime" >
                                <DatePicker
                                        onChange={this.DatePickers}
                                        value={date}
                                        format={'dd-MM-y'}
                                        dayPlaceholder={'dd'}
                                        monthPlaceholder={'mm'}
                                        yearPlaceholder={'yyyy'}
                                        returnValue={'start'}
                                        className={"datepicker"}
                                        maxDate={new Date()}
                                    />
                                </div>  
                        </div>

                    </div>
                    <hr/>

                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                {/* <div>
                                    <label htmlFor="sportType">Sport<span className="required_text">*</span></label>
                                    <select id="sportType" className="form-control" defaultValue=''
                                    onChange={this.onInputChange} name="type">
                                        <option value=''>Choose...</option>
                                        <option value="Cycling" selected={type=="Cycling"?true:false}>Cycling</option>
                                        <option value="Running" selected={type=="Running"?true:false}>Running</option>
                                        <option value="Swimming" selected={type=="Swimming"?true:false}>Swimming</option>
                                        <option value="Walking" selected={type=="Walking"?true:false}>Walking</option>
                                        <option value="Stairs" selected={type=="Stairs"?true:false}>Stairs</option>
                                        <option value="Steps" selected={type=="Steps"?true:false}>Steps</option>
                                        <option value="Exercise" selected={type=="Exercise"?true:false}>Exercise</option>
                                    </select>
                                </div> */}
                                 {type == 'Stairs'?
                                <div>
                                    <label htmlFor="workTitle">Stairs Count<span className="required_text">*</span></label>
                                    <input type="number"  className="form-control" id="link" placeholder="Enter Count"
                                    onChange={this.onInputChange} name="stairs" min="1" max="1000" value={this.state.stairs}/>
                                </div>
                                :
                                type =='Steps'?
                                <div>
                                    <label htmlFor="workTitle">Steps Count<span className="required_text">*</span></label>
                                    <input type="number"  className="form-control" id="link" placeholder="Enter Steps"
                                    onChange={this.onInputChange} name="steps" min="1" max="50000" value={this.state.steps}/>
                                </div>
                                :
                                type !='Exercise'?
                                <div>
                                <label htmlFor="distanceinput" className="">Distance<span className="required_text">*</span></label>
                                <form className="form-inline distance_form">
                                    <input type="number" className={msgcode == 2?"form-control redalert":"form-control"} id="distanceinput" placeholder="" 
                                    onChange={this.onInputChange} name='distance' value={distance} min="0"/>
                                    <select className="form-control" id="exampleSelect1"
                                    onChange={this.onInputChange} name='distanceType'>
                                        <option value='km'>kilometers</option>
                                        <option value='mtr'>meters</option>
                                        <option value='miles'>miles</option>
                                    </select>
                                </form>
                            </div>
                            :null}
                            </div>
                            <div className="form-group col-md-4">
                                 <label htmlFor="workTitle">Title<span className="required_text">*</span></label>
                                <input type="text" value={title} className="form-control" id="workTitle" placeholder="Title"
                                 onChange={this.onInputChange} name="title"/>
                            </div>
                            <div className="form-group col-md-4">
                            <label htmlFor="workTitle">Link(for more than 10 KM)<span className="required_text">*</span></label>
                                <input type="text"  className="form-control" id="link" placeholder="Link"
                                 onChange={this.onInputChange} name="link" value={link}/>
                            </div>
                        </div>
                        <div className="form-row">
                            {/* <div className="form-group col-md-6">
                              
                            </div> */}
                            <div className="form-group col-md-12">
                                 <label htmlFor="workDescription">Description</label>
                                <textarea  className="form-control" id="workDescription" placeholder="How did it go?"
                                 onChange={this.onInputChange} name='description' value={description}/>
                            </div>
                        </div>
                        <div className="form-row">
                           
                            <div className="form-group col-md-4">
                                <label htmlFor="userPhoto">Photo<span className="required_text">*</span></label>
                                <div className={msgcode == 'l'?"post_imgupload redalert":"post_imgupload"} style={{ margin:0}} >
                                    {this.state.Photo !=null && this.state.Photo!=''?
                                    <div className='crp_imgdisply'>
                                        <img src={URL.createObjectURL(this.state.Photo)} /> 
                                        <i className="fa fa-times-circle-o" aria-hidden="true" onClick={this.imageRemove.bind(this)}></i>
                                    </div>:
                                    post_media!=''?
                                    <div className='crp_imgdisply'>
                                        <img src={post_media} /> 
                                        <i className="fa fa-times-circle-o" aria-hidden="true" onClick={()=>{this.setState({post_media:""})}}></i>
                                    </div>:
                                    <Dropzone  ref={this.dropzoneRef} onDrop={(acceptedFiles)=>this.getPhoto(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}>
                                        <input {...getInputProps()} multiple={false}/>
                                        <label className="img_upldico" htmlFor='post_imgupload_inpt'>
                                        <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                                        <p>Drag and drop OR Click to upload Screenshot</p>
                                     </label>
                                  </div>
                                )}
                                  </Dropzone>
                                } 
                              </div>
                            </div>
                            <div className="form-group col-md-8">
                                {/* <div className='timed-btn'>
                                    <span>Is Timed Event?</span>
                                    <Switch onChange={this.handleChange} checked={this.state.isTimedEvent} />
                                </div> */}
                                {isTimedEvent?
                                <div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="workTitle">Cyruns Event<span className="required_text">*</span></label>
                                        <input type="text"  className="form-control" id="link" placeholder="Event Name"
                                        onChange={this.onInputChange} name="cyruns_event" value={cyruns_event}/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="workTitle">BIB No.<span className="required_text">*</span></label>
                                        <input type="text"  className="form-control" id="link" placeholder="BIB No"
                                        onChange={this.onInputChange} name="bib_no" value={bib_no}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="Comments">Comments</label>
                                        <textarea  className="form-control" id="Comments" placeholder="Comments"
                                        onChange={this.onInputChange} name='comments' value={comments}/>
                                    </div>
                                </div>
                                </div>
                              :""  }
                                
                               
                            </div>
                        </div>
                        <div className='timed-btn'>
                            <span>Is Timed Event?</span>
                            <Switch onChange={this.handleChange} checked={this.state.isTimedEvent}
                            height={20} width={40}/>
                        </div>
                    </form>
                    <div className='addEntryButtons'>
                    {!loader ?
                        <Button className='addEntrycrtbtn' onClick={this.addManual.bind(this)}>Update</Button>
                        :
                        <Button className='addEntrycrtbtn'>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading...
                        </Button>

                    }
                        {/* <Button className='addEntrycrtbtn' onClick={this.addManual.bind(this)}>Create</Button> */}
                        <Button onClick={()=>this.props.history.push('/')}>Cancel</Button>
                    </div>

                </div>

                </div>
               

            </div>
        );
    }

}
export default EditWorkout;