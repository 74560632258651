import * as React from 'react';

import axios from 'axios';

import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cryptoJSON from 'crypto-json';
import '../styles/Home.css';
import cookie from 'react-cookies';


interface Props {
    history:any;
}
interface State {
    [x: number]: any;
    loader:boolean;
    PasswordToggle:boolean;
    email:any;
    password:any;
    errors:any;
}


class Login extends React.PureComponent <Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            loader:false,
            PasswordToggle:false,
            email:'',
            password:'',
            errors: {
                email: '',
                password: '',
              }
        };
    }
     validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
          (val:any) => val.length > 0 && (valid = false),
        );
        return valid;
      }
   async Login(e: any){
        e.preventDefault();
        const {email, password} = this.state;
        if(this.validateForm(this.state.errors) && email!=='' && password!=='') {
              this.setState({loader:true});
              let init:any = {
                  method: 'POST',
                  headers: { 'content-type': 'multipart/form-data',
                              'Accept': 'application/json'},
                  mode: 'cors',
                  cache: 'default',
                  dataType: 'json',
                  async:    true,
                };
               
              let formData = new FormData();
              formData.append('email',email);
              formData.append('password',password);
          
          await axios.post("https://api.cyruns.in/api/athletes.php?action=login", formData,init)
              .then(response => {
                 // console.log(response.data);
                this.setState({loader:false});
                if(response.data.msgcode == 0){
                    toast.success(response.data.msg);
                // const encryptUser = cryptoJSON.encrypt(response.data.user,'monkdesk@2019');
                // sessionStorage.setItem('User', JSON.stringify(encryptUser) );
                   cookie.save('User',response.data.user, { path: '/' , maxAge: 43200});
                    this.props.history.push({ 
                        pathname: '/my-workout',
                        state: response.data.msg
                       });
                       window.location.reload();
                }else if(response.data.msgcode == 2){
                    this.props.history.push({ 
                        pathname: '/user-detailform',
                        state:{msg: response.data.msg,User: response.data.user}
                       });
                    window.location.reload();
                }
                else{
                    toast.error(response.data.msg);
                }
              })
              .catch(error => {
                  const Msg='Something went to wrong please Try later... OR Check your internet connection';
                    toast.error(Msg);
                     this.setState({loader:false});
                    console.log(error);
               });           
          }else{
            toast.error("Form is not valid !");
          }
    }
    onInputChange(e: any) {
        e.preventDefault();
        const { name, value } = e.target;
        let errors = this.state.errors;
        const validEmailRegex = 
            RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        switch (name) {
            case 'email': 
            errors.email = 
            //   validEmailRegex.test(value)
            //     ? ''
            //     : 'Email is not valid!';
            value.length < 1
            ? 'enter email or athlete id'
            : '';
            break;
          case 'password': 
            errors.password = 
              value.length < 8
                ? 'Password must be 8 characters long!'
                : '';
            break;
          default:
            break;
        }
        this.setState({errors, [name]: value}, ()=> {
        })
        
    }
    PasswordToggle(){
        this.setState({PasswordToggle:!this.state.PasswordToggle});
    }
    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          this.Login(event);
        }
      }
    render() {
        const {loader,PasswordToggle,errors} =this.state;
        return (
                <div className="login-container" >
                     <ToastContainer/>
                    <form className='login_form'>
                        <div className='log_header'>
                            <a href="#"><img src='Images/runner.svg'/></a>
                            <h3>Welcome Back :)</h3>
                            <p>To keep connected with us please login email address and password.</p>
                        </div>
                        <div className='login_fcont'>
                            <div className="form-group">
                                <Input className='log_input' name="email" label="Email Or Athlete Id" type='text' floatingLabel={true} onChange={(e: any) => this.onInputChange(e)} />
                                {errors.email.length > 0 && 
                                    <span className='error_msg'>{errors.email}</span>}
                                <Input className='log_input' name="password" label="Password" type={PasswordToggle?'text':'password'} floatingLabel={true} 
                                onChange={(e: any) => this.onInputChange(e)}  onKeyPress={this.handleKeyPress}/>
                                {errors.password.length > 0 && 
                                        <span className='error_msg'>{errors.password}</span>}
                                <span className='pass_togle' onClick={this.PasswordToggle.bind(this)}>{PasswordToggle?'Hide':'Show'}</span>
                            </div>
                        </div>
                        <div className='forgot-pass-link'>
                            <a href="#" data-toggle="modal" data-target="#forgotPasswordModel">forgot your password?</a>
                        </div>
                        <div className='log_btn'>
                            {!loader ?
                            <Button variant="raised" type="button" onClick={this.Login.bind(this)}>Login</Button> :
                            <button className="" type="button" disabled>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading...
                            </button>
                            }
                        </div>
                      
                    </form>
                </div>
 
        );
    }

}

export default Login;
//export default withRouter(Login);
