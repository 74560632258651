import React from 'react';
import logo from './logo.svg';
import './App.css';
import './styles/Dashboard.css';
import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/css/bootstrap-theme.css';

import Navigator from './utils/Navigations/Navigator';
import {Provider} from 'react-redux';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


interface Props {
  history:any;
  location:any;
}
interface State{

}
class App extends React.Component <Props, State>{

  render() {
    return (   
          <Navigator history={this.props.history} location={this.props.location}/>  
    );
  }
}

export default App;
