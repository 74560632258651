import * as React from 'react';
import Avatar from 'react-avatar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'muicss/lib/react/button';

interface Props {
    post:any;
    Likes:any;
    Comments:any;
    todayDate:any;
}
interface State {
 
   
}
class PostlikeNcomment extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
           
        }
    }
    
   
    render() {
        const {post,Likes,Comments,todayDate} = this.props;
    
        return (
            <div className="modal-dialog modal-dialog-centered" role="document">
                 <ToastContainer/>
                <div className="modal-content">
                    <div className="modal-header">
                     <div className="post_img_ico">
                        {
                            post.user_photo!=''?
                            <img src={post.user_photo}/>:
                            <Avatar name={post.name} size='50px' round={true} color='#51648b' style={{fontWeight:600}}/>
                        }
                        </div>
                        <h5 className="modal-title likeview-title" id="exampleModalLongTitle">{post.post_title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='lcview_body'>
                        <Tabs>
                            <TabList>
                            <Tab>Likes ({post.like_count})</Tab>
                            <Tab>Comments ({post.comment})</Tab>
                            </TabList>
                        
                            <TabPanel>
                                <div className="lcv_likelist">
                                    {Likes!='' && Likes!=null ?
                                    Likes.map((like,index)=>{
                                    return <div key={index} className="likelist_body">
                                            <div className="lcvie_img">
                                                {
                                                like.image!=''?
                                                <img src={like.image}/>:
                                                <Avatar name={like.name} size='40px' round={true} color='#51648b' style={{fontWeight:600}}/>
                                                }
                                            </div>
                                            <div className="lcvie_name">
                                               <a href=""><p>{like.name}</p></a>
                                            </div>
                                        </div>
                                       
                                    })
                                    :post.like_count==0?
                                    <div>No any likes found</div>
                                :''}
                                   
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="lcv_likelist">
                                {Comments!='' && Comments!=null ?
                                    Comments.map((comment,index)=>{
                                    return <div className='likelist_body' key={index}>
                                            <div className="lcvie_img">
                                                {
                                               comment.image!=''?
                                                <img src={comment.image}/>:
                                                <Avatar name={comment.name} size='40px' round={true} color='#51648b' style={{fontWeight:600}}/>
                                                }
                                            </div>
                                            <div className="lcvcom_name">
                                                <a href=""><p>{comment.name}</p></a>
                                                <p>{comment.comment}</p>
                                            </div>
                                            <div className='lncvcom_date'>
                                                <p>{comment.date==todayDate?'today':comment.date}</p>
                                            </div>
                                        </div>
                                    })
                                :post.comment==0?
                                <div>No any comment found</div>
                            :''}
                                   
                                    
                                </div>
                            </TabPanel>
                        </Tabs>
                        </div>
                    </div>
                    <div className="modal-footer">
                         {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        );
    }


}
export default PostlikeNcomment;