import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


class HomeSlider extends React.Component {

    render() {
        return (
                <OwlCarousel
                    className="owl-theme"
                    items={1}
                    margin={10}
                    nav={true}
                    loop={true}
                    autoplay
                >
                    <div className="item">
                        <img src="../Images/slider1.png" />
                    </div>
                    <div className="item">
                        <img src="../Images/slider2.svg" />
                    </div>
                    <div className="item">
                        <img src="../Images/slider3.png" />
                    </div>
                    <div className="item">
                        <img src="../Images/slider4.svg" />
                    </div>
                </OwlCarousel>
        );

    }
}
export default HomeSlider;