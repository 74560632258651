import * as React from 'react';
import { UserData } from '../services/UserData';
import WeeklyGraph from '../components/weeklyGraph';
import MonthlyGraph from '../components/MonthlyGraph';
import ReportViewModel from '../components/ReportViewModel';

interface Props {

}
interface State {
    profile: any;
    monthList: any;
    month: any;
    title:any;
    [x: number]: any;
}
class MyReport extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
            profile: [],
            monthList: [],
            month: '',
            title:''
        }
    }

    myProfile = async () => {
        const data = await UserData.getUserData();
        const pro = data.profile[0];
        const userImage = data.userImage;
        this.setState({ profile: pro });
        const monthList = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        this.setState({ monthList });
    }
    componentDidMount = async () => {
        this.myProfile();
    }
    onInputChange = async (e) => {
        const [name, value] = e.target;
        this.setState({ [name]: value });
    }
    render() {
        const { profile, monthList, month } = this.state;
        return (
            <div className='myworkout-main container sec_workout'>

                <div className="section-title-first">
                    <div className="challenger-id">
                        <span>Athlete ID</span>
                        <p>{profile.athlete_id}</p>
                    </div>
                    <div className='analysis_img'>
                        <img src='Images/stats.png' />
                        <h1>My Report</h1>
                    </div>
                   
                </div>
                <div className="table-responsive">
                    <table className="table table-striped report_table" id="filter_data">
                        <thead>
                            <tr>
                                <th>
                                    <select className="form-control filter-rs" id="custom_filter[return_duration]"
                                        name='month' onChange={this.onInputChange}>
                                        <option value="">Choose month</option>
                                        {monthList != null && monthList != '' ?
                                            monthList.map((Month, i) => {
                                                return <option value={i + 1} key={i}
                                                    selected={month == i + 1 ? true : false}>{Month}</option>
                                            })
                                            :
                                            <option value="">Nos.</option>
                                        }
                                    </select>
                                </th>
                                <th>Cycling</th>
                                <th>Running</th>
                                <th>Walking</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>General Rank</th>
                                <td>10</td>
                                <td>20</td>
                                <td>30</td>
                                <td>
                                <button type="button" className="btn btn-info act-btns waves-effect" data-toggle="modal" data-target="#reportViewModel"
                                onClick={()=>this.setState({title:"General"})}>
                                    <span className="fa fa-eye"></span>View
                                </button>
                                </td>
                            </tr>
                            <tr>
                                <th>Gender Rank</th>
                                <td>10</td>
                                <td>20</td>
                                <td>30</td>
                                <td>
                                <button type="button" className="btn btn-info act-btns waves-effect" data-toggle="modal" data-target="#reportViewModel"
                                 onClick={()=>this.setState({title:"Gender"})}>
                                    <span className="fa fa-eye"></span>View
                                </button>
                                </td>
                            </tr>
                            <tr>
                                <th>Group/Club Rank</th>
                                <td>10</td>
                                <td>20</td>
                                <td>30</td>
                                <td>
                                <button type="button" className="btn btn-info act-btns waves-effect" data-toggle="modal" data-target="#reportViewModel"
                                onClick={()=>this.setState({title:"Group/Club"})}>
                                    <span className="fa fa-eye"></span>View
                                </button>
                                </td>
                            </tr>
                            <tr>
                                <th>State Rank</th>
                                <td>10</td>
                                <td>20</td>
                                <td>30</td>
                                <td>
                                <button type="button" className="btn btn-info act-btns waves-effect" data-toggle="modal" data-target="#reportViewModel"
                                onClick={()=>this.setState({title:"State"})}>
                                    <span className="fa fa-eye"></span>View
                                </button>
                                </td>
                            </tr>
                            <tr>
                                <th>City Rank</th>
                                <td>10</td>
                                <td>20</td>
                                <td>30</td>
                                <td>
                                <button type="button" className="btn btn-info act-btns waves-effect" data-toggle="modal" data-target="#reportViewModel"
                                onClick={()=>this.setState({title:"City"})}>
                                    <span className="fa fa-eye"></span>View
                                </button>
                                </td>
                            </tr>
                            <tr>
                                <th>Age Rank</th>
                                <td>10</td>
                                <td>20</td>
                                <td>30</td>
                                <td>
                                <button type="button" className="btn btn-info act-btns waves-effect" data-toggle="modal" data-target="#reportViewModel"
                                onClick={()=>this.setState({title:"Age"})}>
                                    <span className="fa fa-eye"></span>View
                                </button>
                                </td>
                            </tr>
                            <tr>
                                <th>Calorie Rank</th>
                                <td>10</td>
                                <td>20</td>
                                <td>30</td>
                                <td>
                                <button type="button" className="btn btn-info act-btns waves-effect" data-toggle="modal" data-target="#reportViewModel"
                                onClick={()=>this.setState({title:"Calorie"})}>
                                    <span className="fa fa-eye"></span>View
                                </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered report_table" id="filter_data">
                        <thead>
                            <tr>
                                <th>Race wise workout</th>
                                <th>00 - 05 Km</th>
                                <th>06 - 10 Km</th>
                                <th>11 - 21 Km</th>
                                <th>22 - 42 Km</th>
                                <th>43 - 50 Km</th>
                                <th>51 - 75 Km</th>
                                <th>76 - 100 Km</th>
                                <th>100+ Km</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>No of records</td>
                                <td>10</td>
                                <td>15</td>
                                <td>50</td>
                                <td>25</td>
                                <td>22</td>
                                <td>41</td>
                                <td>58</td>
                                <td>11</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered report_table" id="filter_data">
                        <thead>
                            <tr>
                                <th>Ride wise workout</th>
                                <th>00 - 10 Km</th>
                                <th>11 - 25 Km</th>
                                <th>26 - 50 Km</th>
                                <th>51 - 75 Km</th>
                                <th>76 - 100 Km</th>
                                <th>101 - 150 Km</th>
                                <th>151 - 200 Km</th>
                                <th> 200+ Km</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>No of records</td>
                                <td>10</td>
                                <td>15</td>
                                <td>50</td>
                                <td>25</td>
                                <td>22</td>
                                <td>41</td>
                                <td>58</td>
                                <td>18</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div className='row chart_report'>
                    <div className='col-md-12 col-sm-12 chart_report_grp'>
                        <WeeklyGraph />
                    </div>
                    <div className='col-md-12 col-sm-12 chart_report_grp'>
                        <MonthlyGraph />
                    </div>
                </div> */}
                
                 {/* ReportViewModel */}
                 <div className="modal fade show reportViewModel" id="reportViewModel" role="dialog" >
                    <div className="modal-dialog modal-dialog-centered fullscreenModel" >
                        <ReportViewModel  Title={this.state.title}/>
                    </div>
                </div>

            </div>
        )
    }
}

export default MyReport;