import * as React from 'react';

interface Props {
    icon: any;
    title: any;
    alldata: any;
}
interface State {
    search: any;
}
class AllRanklistModel extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
            search: null
        }
    }
    searchSpace = (event) => {
        let keyword = event.target.value;
        this.setState({ search: keyword })
    }
    render() {
        const { icon, title, alldata } = this.props;
        let item:any;
        if(alldata){
            const items = alldata.filter((data) => {
                if (this.state.search == null)
                    return data
                else if (data.first_name.toLowerCase().includes(this.state.search.toLowerCase()) || data.last_name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    data.rank.toString().includes(this.state.search.toLowerCase()) || data.distance.toLowerCase().includes(this.state.search.toLowerCase())) {
                    return data
                }
            }).map((data, index) => {
                if(index < 100){
                    return (
                        <li key={index}>
                            <p>
                                <span className="span_number" style={{ background: "rgb(211, 64, 70)" }}>{data.rank}</span>
                                <span className="span_name">{data.first_name + ' ' + data.last_name}</span>
                                {title=='Climbers'?
                                    <span className="rank_in">{data.stairs +` (`+data.days + ' Days)'}</span>
                                    :
                                    <span className="rank_in">{data.distance}</span>
                                }
                            </p>
                        </li>
                    )
                }
               
            })
            item = items;
        }
       
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        <div className="rank_img_modal">
                            <img src={'./Images/' + icon} />
                        </div>
                        <span className="title-test">Top 100 {title} rank list</span>
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="top_5_all_tt loader" data-year="2020">
                        <input type="text" name="search" id="keyword_5" className="form-control"
                            placeholder="Search Your Rank" style={{ textAlign: 'center', marginBottom: 10 }}
                            onChange={this.searchSpace} autoComplete="off"/>
                        <div className="top_five_all_list">
                            <ul id="t5ma" className="top_5_board">
                                {/* {alldata ?
                                    alldata.map((data, index) => {
                                        return <li key={index}>
                                            <p>
                                                <span className="span_number" style={{ background: "rgb(211, 64, 70)" }}>{data.rank}. </span>
                                                <span className="span_name">{data.first_name + ' ' + data.last_name}</span>
                                                <span className="rank_in">{data.distance}</span>
                                            </p>
                                        </li>
                                    })
                                    : ""} */}
                                {item}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AllRanklistModel;