import * as React from 'react';
import axios from 'axios';

import Avatar from 'react-avatar';
import '../styles/myProfile.css';
import { AsyncStorageService } from '../services/AsyncStorage';
import ReactTooltip from 'react-tooltip';
import Input from 'muicss/lib/react/input';
import { ToastContainer, toast } from 'react-toastify';
import { UserData } from '../services/UserData';

interface Props {
    location: any;
    history: any;
}
interface State {
    [x: number]: any;
    user: any;
    userImage: any;
    currentpassword: any;
    newpassword: any;
    retypepassword: any;
    Dob:any;
}
class MyProfile extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            userImage: '',
            currentpassword: '',
            newpassword: '',
            retypepassword: '',
            Dob:''
        };
    }

    resateState = () => {
        setTimeout(() => {
            this.props.history.replace();
        }, 5000);
    }
    componentDidMount = async () => {
        const Msg = this.props.location.state;
        if (Msg) {
            toast.success(Msg);
            this.resateState();
        }
        const data = await UserData.getUserData();
        const pro = data.profile[0];
        const userImage = data.userImage;
        const Dob = data.Dob;
        this.setState({ user: pro, userImage ,Dob});
    }
    onInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    passwordChange = async () => {
        const user = await AsyncStorageService.getUser();
        const userId = user.user_id;
        const { currentpassword, newpassword, retypepassword } = this.state;
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        if (newpassword.length > 7 && retypepassword.length > 7) {
            let formData = new FormData();
            formData.append('user_id', userId);
            formData.append('currentpassword', currentpassword);
            formData.append('newpassword', newpassword);
            formData.append('retypepassword', retypepassword);
            await axios.post("https://api.cyruns.in/api/athletes.php?action=changepassword", formData, init)
                .then(response => {
                    if (response.data.msgcode == 0) {
                        toast.success(response.data.msg);
                    } else {
                        toast.error(response.data.msg)
                    }
                })
        } else {
            toast.error('Password must be 8 characters long!');
        }

    }
    render() {
        const { user, userImage ,Dob} = this.state;
        // console.log(user);
        return (
            <div className="myprofile_screen container">
                <ToastContainer />
                <div className='prof_name'>
                    <div className='pr_fblk'>
                        {userImage != '' ?
                            <div className='prof_img'>
                                <img src={userImage} alt="" />
                            </div>
                            :
                            <Avatar name={user.first_name} size='100px' round={true} color='rgb(169 174 75)' style={{ fontWeight: 600 }} />
                        }
                        <div className="challenger-id">
                            <span>Athlete ID</span>
                            <p>{user.athlete_id}</p>
                        </div>
                    </div>

                    <h3>{user.first_name + ' ' + user.last_name}
                        <a href="/edit-profile">
                            <i className="fa fa-pencil" aria-hidden="true" data-tip data-for='editProfile'></i>
                        </a>
                    </h3>
                    <p className='pro_email'><i className="fa fa-envelope" aria-hidden="true"></i>{user.user_email}</p>
                    <ReactTooltip id='editProfile' type='info' >
                        <span>Edit Profile</span>
                    </ReactTooltip>
                    <p className='pro_email'>
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        {user.address_line1 + ' , ' + user.address_line2 + ' - ' + user.pincode}
                    </p>
                    <p>
                        {/* <i className="fa fa-id-card" aria-hidden="true"></i> */}
                        {user.city + ', ' + user.state + ', ' + user.country}
                    </p>
                </div>
                <div className='pro_otherdetl'>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <div className='table-responsive'>
                                <table className="table table-striped prodtltable">
                                    {/* <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Value</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-phone"></i>
                                                     Contact Number
                                                 </label>
                                            </th>
                                            <td>
                                                <span>{user.contact_number?'+' +user.contact_number.substr(0,2)+' '+user.contact_number.substr(2,12):"-"}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-calendar"></i>
                                                    Date of Birth
                                                </label>
                                            </th>
                                            <td>
                                                <span>{Dob}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-tint"></i>
                                                    Blood Group
                                                </label>
                                            </th>
                                            <td>
                                                <span>{user.blood_group}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-circle"></i>
                                                    Shoes Brand and Model
                                                </label>
                                            </th>
                                            <td>
                                                <span>{user.shoes_brand}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-circle"></i>
                                                    Preferred Hydration Supplement
                                                </label>
                                            </th>
                                            <td>
                                                <span>{user.preferred_supplement}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-facebook-official"></i>
                                                    FB Link
                                                </label>
                                            </th>
                                            <td>
                                                <span>
                                                {user.fb_link != "" ?
                                                    <a href={user.fb_link} target="_blank">{user.fb_link}</a>
                                                : '-'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-circle"></i>
                                                    Emergency Contact Name
                                                </label>
                                            </th>
                                            <td>
                                                <span>{user.emergency_contact_name}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='col-12 col-md-6'>
                            <div className='table-responsive'>
                                <table className="table table-striped  prodtltable">
                                    {/* <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Value</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label><i className="fa fa-whatsapp"></i>Whatsapp Number:</label>
                                            </th>
                                            <td>
                                                <span>{user.whatsup_number?'+'+user.whatsup_number.substr(0,2)+' '+user.whatsup_number.substr(2,12):"-"}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                <i className="fa fa-users"></i>
                                                Group Name
                                                </label>
                                            </th>
                                            <td>
                                                <span>{user.club_name}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-circle"></i>
                                                    T-shirt Size
                                                </label>
                                            </th>
                                            <td>
                                                <span>{user.tshirt_size}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-clock-o"></i>
                                                    GPS Watch and Model
                                                </label>
                                            </th>
                                            <td>
                                                <span>{user.gps_watch}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-history"></i>
                                                    Medical History
                                                </label>
                                            </th>
                                            <td>
                                                <span>{user.medical_history}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-instagram"></i>
                                                    Instagram Link
                                                </label>
                                            </th>
                                            <td>
                                                <span>
                                                {user.insta_link != "" ?
                                                    <a href={user.insta_link} target="_blank">{user.insta_link}</a>
                                                : '-'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='prdetail_box_inlin'>
                                                <label>
                                                    <i className="fa fa-volume-control-phone"></i>
                                                    Emergency Contact Number
                                                </label>
                                            </th>
                                            <td>
                                               <span>{user.emergency_contact_no?'+'+user.emergency_contact_no.substr(0,2)+' '+user.emergency_contact_no.substr(2,12):"-"}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>       
                </div>

                <div className='edit_passlink'>
                    <p>
                        <a href="#" data-toggle="modal" data-target="#changePasswordModel">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                        Change Password ?..</a>
                    </p>
                </div>

                {/* changePasswordModel */}
                <div className="modal fade show" id="changePasswordModel" role="dialog" >
                    <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header viewDetailheader">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                         Change Password
                                    </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* <div>
                                        <p>Enter email address to request instruction on how to reset your password</p>
                                    </div> */}
                                <div>
                                    <div className="form-group">
                                        <Input className='log_input' name="currentpassword" label="Current password" type='text' floatingLabel={true} onChange={this.onInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <Input className='log_input' name="newpassword" label="New password" type='text' floatingLabel={true} onChange={this.onInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <Input className='log_input' name="retypepassword" label="Retype password" type='text' floatingLabel={true} onChange={this.onInputChange} />
                                    </div>
                                </div>
                                <div className='lockinstr'>
                                    * All fields should be mandatory
                                    </div>
                            </div>
                            <div className="modal-footer log_btn">
                                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">CLOSE</button> */}
                                <button type="button" className="btn btn-primary" onClick={this.passwordChange}>Change</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}
export default MyProfile;