import * as React from 'react';
import axios from 'axios';
import {AsyncStorageService} from './AsyncStorage';
import cookie from 'react-cookies';

let init:any = {
    method: 'POST',
    headers: { 'content-type': 'multipart/form-data',
                'Accept': 'application/json'},
    mode: 'cors',
    cache: 'default',
    dataType: 'json',
    async:    true,
  };
  

  export class UserData {
  
    static async getUserData() {
      try {
        const user =  await AsyncStorageService.getUser();
        const userId = user.user_id;
        const profile:any = [];
        let Dob:any; 
        let userImage;
      init = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
        let formData = new FormData();
        formData.append('user_id',userId);
        await axios.post("https://api.cyruns.in/api/athletes.php?action=profiledetail", formData,init)
          .then(response => {
             // console.log(response.data);
            if(response.data.msgcode == 0){
                profile.push(response.data.profile);
                userImage = response.data.photo_url
                Dob = response.data.dob
            }
            else{
            }
          })
          .catch(error => {
                console.log(error);
           });
        return ({profile,userImage,Dob});
      } catch (e) {
        return Promise.reject(e);
      }
    }
    static clearUser() {
     // return sessionStorage.clear();
      return   cookie.remove('User',{ path: '/' });
    }
  }
  