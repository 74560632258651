import * as React from 'react';

interface Props {
    workOut:any;
}
interface State {

}
class WorkoutViewModel extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const {workOut} = this.props;
        return (
            <div className="modal-content">
                <div className="modal-header viewDetailheader">
                    <h5 className="modal-title" id="exampleModalLongTitle">Workout Details</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='w_viewImg'>
                                {workOut.post_media!=""?
                                     <img src={workOut.post_media} alt=""/>
                                    :
                                    <img src="https://seeba.se/wp-content/themes/consultix/images/no-image-found-360x260.png"/>
                                }
                            </div>
                        </div>
                        <div className='col-md-8'>
                        <div className="table-responsive">
                        <table className="table table-striped viewDetail">
                            <thead>
                                <tr className='viewDetailtitle'>
                                    <th colSpan={2}>
                                        {workOut.post_title?workOut.post_title:'-'}
                                    </th>
                                </tr>
                                <tr className='viewDetaildesc'>
                                    <td colSpan={2}>
                                     {workOut.post_desc!=""?workOut.post_desc:"-"}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Date</th>
                                    <td>{workOut.work_date}</td>
                                </tr>
                                <tr>
                                    <th>Duration</th>
                                    <td>{workOut.duration}</td>
                                </tr>
                                <tr>
                                    <th>Distance(KM)</th>
                                    <td>{workOut.distance}</td>
                                </tr>
                                <tr>
                                    <th>Sport(Type)</th>
                                    <td>{workOut.w_type}</td>
                                </tr>
                                <tr>
                                    <th>Timed Event</th>
                                    <td>
                                        {workOut.is_time_event?
                                         <i className="fa fa-check tevent_ico" aria-hidden="true"></i>
                                            :"-"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Link</th>
                                    <td>
                                        {workOut.w_link!=""?
                                        <a href={workOut.w_link} target='_blank'>{workOut.w_link}</a>
                                         :"-"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        </div>

                    </div>
                    
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                </div>
            </div>
        )
    }

}

export default WorkoutViewModel;