import * as React from 'react';
import axios from 'axios';
import Avatar from 'react-avatar';

import Button from 'muicss/lib/react/button';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

import Loader from 'react-loader-spinner'

import {AsyncStorageService} from '../services/AsyncStorage';
import PostlikeNcomment from '../components/PostlikeNcomment';
import If from './If';
import { ToastContainer, toast } from 'react-toastify';

import ImgsViewer from 'react-images-viewer';

interface Props {
    selectItem:any;
}
interface State {
    userId:any;
    userName:any;
    userImage:any;
    CommentBoxShow: boolean;
    loader:boolean;
    postData:any;
    postId:any;
    likePostId:any;
    postComment:any;
    selectPost:any;
    lncvcomment:any;
    lncvlikedata:any;

   
}

class SocialPost extends React.Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            userId:'',
            userName:'',
            userImage:'',
            CommentBoxShow:false,
            loader:false,
            postData:[],
            postId:'',
            likePostId:'',
            postComment:'',
            selectPost:[],
            lncvcomment:[],
            lncvlikedata:[],
           
        }
    }

   async componentDidMount(){
    const user =  await AsyncStorageService.getUser();
    const userId = user.user_id;
    this.setState({ userId});
    let init:any = {
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data',
                    'Accept': 'application/json'},
        mode: 'cors',
        cache: 'default',
        dataType: 'json',
        async:    true,
      };
     
    let formData = new FormData();
    formData.append('user_id',userId);
    await axios.post("https://api.cyruns.in/api/athletes.php?action=allpost", formData,init)
      .then(response => {
        this.setState({loader:false});
        if(response.data.msgcode == 0){
            this.setState({postData:response.data.postdata,
                userName:response.data.user_name,
                userImage:response.data.user_image
            });
        }
        else{
            toast.error(response.data.msg);
        }
      })
      .catch(error => {
          const Msg='Something went to wrong please Try later... OR Check your internet connection';
            toast.error(Msg);
             this.setState({loader:false});
            console.log(error);
       });
      }

   async likePost(id){
    const audioTune = new Audio('../Images/notification.mp3');
        this.setState({likePostId:id});
        const {userId} = this.state;
        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
         
        let formData = new FormData();
        formData.append('user_id',userId);
        formData.append('post_id',id);
        await axios.post("https://api.cyruns.in/api/athletes.php?action=like", formData,init)
          .then(response => {
            if(response.data.msgcode == 0){
                audioTune.play();
               this.componentDidMount();
            }
            else{
                toast.error(response.data.msg);
            }
          })
          .catch(error => {
              const Msg='Something went to wrong please Try later... OR Check your internet connection';
                toast.error(Msg);
                console.log(error);
           });

    }

    async commentPost(id){
        const {userId,postComment} = this.state;
        if(postComment!=''){
            let init:any = {
                method: 'POST',
                headers: { 'content-type': 'multipart/form-data',
                            'Accept': 'application/json'},
                mode: 'cors',
                cache: 'default',
                dataType: 'json',
                async:    true,
              };
             
            let formData = new FormData();
            formData.append('user_id',userId);
            formData.append('post_id',id);
            formData.append('comment',postComment);
            await axios.post("https://api.cyruns.in/api/athletes.php?action=comment", formData,init)
              .then(response => {
                if(response.data.msgcode == 0){
                    this.setState({postId:''});
                   this.componentDidMount();
                }
                else{
                    toast.error(response.data.msg);
                }
              })
              .catch(error => {
                  const Msg='Something went to wrong please Try later... OR Check your internet connection';
                    toast.error(Msg);
                    console.log(error);
               });
        }else{
            toast.error('please write comment first');
        }
    }

    async likeNcommentvData(post){
        this.setState({selectPost:post,lncvcomment:[],
            lncvlikedata:[]})
            if(post.post_id!=''){
                let init:any = {
                    method: 'POST',
                    headers: { 'content-type': 'multipart/form-data',
                                'Accept': 'application/json'},
                    mode: 'cors',
                    cache: 'default',
                    dataType: 'json',
                    async:    true,
                  };
                 
                let formData = new FormData();
                formData.append('post_id',post.post_id);
                await axios.post("https://api.cyruns.in/api/athletes.php?action=lcview", formData,init)
                  .then(response => {
                    if(response.data.msgcode == 0){
                        this.componentDidMount();
                        this.setState({lncvcomment:response.data.comment,
                        lncvlikedata:response.data.likedata});
                    }
                    else{
                        toast.error(response.data.msg);
                    }
                  })
                  .catch(error => {
                      const Msg='Something went to wrong please Try later... OR Check your internet connection';
                        toast.error(Msg);
                        console.log(error);
                   });
            }
        }
    
    imageView=(data:any)=>{
        const imgArr:any=[];
       

        for(let i=0;i<data.images.length;i++){
            if(data.images[i].split('.')[1]=='mp4' || data.images[i].split('.')[1]=='mkv')
                imgArr.push({url:data.img_path+'/'+data.images[i], type: 'video',altTag: 'some video' });
            else
             imgArr.push({url:data.img_path+'/'+data.images[i], type: 'photo', altTag: 'some other image' });
            }
            this.props.selectItem({ lightboxOpen: true,imgArr });
    }
    gotoPrevious(){

    }
    gotoNext(){

    }
    callbackFunction(){

    }

    render() {
        const {CommentBoxShow,loader,selectPost,postData,postId,
            likePostId,lncvlikedata,lncvcomment,userName,userImage} = this.state;
        
        const today = new Date();
        const Month = today.toLocaleString('default', { month: 'long' });
        const todayDate = Month+' '+today.getDate()+', '+today.getFullYear();
        const yestardayDate = Month+' '+(today.getDate()-1)+', '+today.getFullYear();
        return (
            <div className='social_post_screen'>
                <ToastContainer/>
              
               { postData !='' && postData!=null ?
                    postData.map((Post:any,i:any)=>{
                        return (
                            <div className="post_main" key={i}>
                            <div className="post_frow">
                                <div className="post_img_ico">
                                    {Post.user_photo !='' && Post.user_photo != null ?
                                     <img src={Post.user_photo} />:
                                     <Avatar name={Post.name} size='50px' round={true} color='#51648b' style={{fontWeight:600}}/>
                                    }
                                </div>
                                <div className="p_nameNdate">
                                    <a href="">{Post.name}</a>
                                    <If show={Post.post_type=='normal'}>
                                        <span>{Post.created_date == todayDate?'Today':Post.created_date==yestardayDate?'Yesterday':Post.created_date}</span>
                                    </If>
                                    <If show={Post.post_type=='workout'}>
                                        <span>{Post.work_date == todayDate?'Today':Post.work_date==yestardayDate?'Yesterday':Post.work_date}
                                        {/* {' at ' + Post.work_time} */}
                                        </span>
                                    </If>
                                    {/* <span>October 13, 2020 at 4:20 PM</span> */}
                                </div>
                            </div>
        
                            <div className="post_Srow">
                                <div className='post_title'>
                                    <a href=""><h3>{Post.post_title}</h3></a>
                                </div>
                                <div className='post_description'>
                                    <p>{Post.post_desc}</p>
                                </div>
                            </div>
                            <If show={Post.post_type=='workout'}>
                                <div className='manual_post_data'>
                                <div>
                                    <span>Distance</span>
                                    <p>{Post.distance+' Km'}</p>
                                </div>
                                <div>
                                    <span>Pace</span>
                                    <p>{Post.pace+' /Km'}</p>
                                </div>
                                <div>
                                    <span>Time</span>
                                    <p>{Post.duration}</p>
                                </div>
                                </div>
                            </If>
                            <If show={Post.images[0] != ''?true:false}>
                                <div className='post_medea'>
                                    {Post.images[0].split('.')[1]=='mp4' || Post.images[0].split('.')[1]=='mkv'?
                                     <Player>
                                         <source src={Post.img_path+'/'+Post.images[0]} />
                                    </Player>
                                    :
                                     <img src={Post.img_path+'/'+Post.images[0]} alt="" onClick={()=>this.imageView(Post)}/>
                                   
                                    }
                                </div>
                            </If>
                           
                            <div className='post_lNc'>
                                <div className='po_lnc_icons'>
                                    <div className='post_like'>
                                    {Post.you_like || Post.post_id==likePostId?
                                         <Button>
                                          <i className="fa fa-thumbs-up" aria-hidden="true" style={{color: '#51648b'}}></i>
                                         </Button>
                                        :
                                        <Button  onClick={()=>this.likePost(Post.post_id)}>
                                         <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                        </Button>}
                                        <span data-toggle="modal" data-target="#likeNcommentModal" 
                                        onClick={()=>this.likeNcommentvData(Post)}>
                                            {Post.you_like && Post.like_count===1?'You like this ':
                                            Post.you_like && Post.like_count>1?"You and " +(Post.like_count-1)  + " other poople like this":
                                            Post.like_count==0?'0':
                                            Post.like_count+" people like this"}
                                        </span>
                                    </div>
                                    <div className='post_comment'>
                                        <Button  onClick={()=>this.setState({postId:Post.post_id})}>
                                            <i className="fa fa-comment-o" aria-hidden="true"></i>
                                        </Button>
                                        <span data-toggle="modal" data-target="#likeNcommentModal" 
                                         onClick={()=>this.likeNcommentvData(Post)}>
                                        {Post.comment}</span>
                                    </div>
                                </div>
        
                                <If show={postId===Post.post_id}>
                                    <hr />
                                    <div className="coment_inpt_main">
                                        <div className="cmnt_img_ico">
                                            {userImage !='' && userImage != null ?
                                                <img src={userImage} />:
                                                <Avatar name={userName} size='35px' round={true} color='#51648b' style={{fontWeight:600}}/>
                                            }
                                        </div>
                                        <div className="form-group coment_inpt">
                                            <input type="text" className="form-control" id="postcomment"  placeholder="Write comment..."
                                            onChange={(e)=>{this.setState({postComment:e.target.value})}} />
                                        </div>
                                        <div className="coment_post_btn">
                                            <Button onClick={()=>this.commentPost(Post.post_id)}>
                                                <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </If>
                            </div>
                        </div>
                        )
                    })
               :
                <div className='screen_loader'>
                    <Loader
                        type="BallTriangle"
                        color="#fc5200"
                        height={100}
                        width={100}
                        timeout={7000} 
                    />
                </div>
           }

    <div className="modal fade" id="likeNcommentModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <PostlikeNcomment post={selectPost} Likes={lncvlikedata} 
        Comments={lncvcomment} todayDate={todayDate}/>
    </div>

       
 {/*///// ////////////////////////////////////////////////////////////////////////// */}
                {/* <div className="post_main">
                    <div className="post_frow">
                        <div className="post_img_ico">
                            <img src="https://lh4.googleusercontent.com/-KP05x-vHIK4/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmmJtl6o85xupdF1OxOHLohvktFMw/s96-c/photo.jpg" />
                        </div>
                        <div className="p_nameNdate">
                            <a href="">ankit sabhadiya</a>
                            <span>October 13, 2020</span>
                        </div>
                    </div>

                    <div className="post_Srow">
                        <div className='post_title'>
                            <a href=""><h3>This is title</h3></a>
                        </div>
                        <div className='post_description'>
                            <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                        </div>
                    </div>

                    <div className='post_medea'>
                        <Player>
                             <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                        </Player>
                    </div>

                    <div className='post_lNc'>
                        <div className='po_lnc_icons'>
                            <div className='post_like'>
                                <Button>
                                    <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                </Button>
                                <span>You and 201 people like this</span>
                            </div>
                            <div className='post_comment'>
                                <Button  onClick={()=>this.setState({CommentBoxShow:true})}>
                                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                                </Button>
                                <span>210</span>
                            </div>
                        </div>

                        <If show={CommentBoxShow}>
                            <hr />
                            <div className="coment_inpt_main">
                                <div className="cmnt_img_ico">
                                    <img src="https://lh4.googleusercontent.com/-KP05x-vHIK4/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmmJtl6o85xupdF1OxOHLohvktFMw/s96-c/photo.jpg" />
                                </div>
                                <div className="form-group coment_inpt">
                                    <input type="text" className="form-control" id="postcomment"  placeholder="Write comment..." />
                                </div>
                                <div className="coment_post_btn">
                                    <Button>
                                        <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                                    </Button>
                                </div>
                            </div>
                        </If>
                      
                    </div>
                </div>

                {/* ////////////////////// */}
                {/* <div className="post_main">
                    <div className="post_frow">
                        <div className="post_img_ico">
                            <img src="https://lh4.googleusercontent.com/-KP05x-vHIK4/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmmJtl6o85xupdF1OxOHLohvktFMw/s96-c/photo.jpg" />
                        </div>
                        <div className="p_nameNdate">
                            <a href="">ankit sabhadiya</a>
                            <span>October 13, 2020</span>
                        </div>
                    </div>

                    <div className="post_Srow">
                        <div className='post_title'>
                            <a href=""><h3>This is title</h3></a>
                        </div>
                        <div className='post_description'>
                            <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                        </div>
                    </div>

                    <div className='post_medea'>
                        <img src="https://demo.hasthemes.com/adda-preview/adda/assets/images/profile/profile-1.jpg" alt="" />
                    </div>


                    <div className='post_lNc'>
                        <div className='po_lnc_icons'>
                            <div className='post_like'>
                                <Button>
                                    <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                </Button>
                                <span>You and 201 people like this</span>
                            </div>
                            <div className='post_comment'>
                                <Button  onClick={()=>this.setState({CommentBoxShow:true})}>
                                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                                </Button>
                                <span>210</span>
                            </div>
                        </div>

                        <If show={CommentBoxShow}>
                            <hr />
                            <div className="coment_inpt_main">
                                <div className="cmnt_img_ico">
                                    <img src="https://lh4.googleusercontent.com/-KP05x-vHIK4/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmmJtl6o85xupdF1OxOHLohvktFMw/s96-c/photo.jpg" />
                                </div>
                                <div className="form-group coment_inpt">
                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Write comment..." />
                                </div>
                                <div className="coment_post_btn">
                                    <Button>
                                        <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                                    </Button>
                                </div>
                            </div>
                        </If>
                      
                    </div>
                </div>  */}

                </div>

               
           
        );
    }

}

export default SocialPost;