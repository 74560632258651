import * as React from 'react';
import axios from 'axios';

import '../styles/Home.css';
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import cryptoJSON from 'crypto-json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cookie from 'react-cookies';

interface Props {
    history:any;
}
interface State {
    [x: number]: any;
    loader:boolean;
    first_name:any;
    last_name:any;
    email:any;
    password:any;
    errors:any;
    isDandi:boolean;
}


class Signup extends React.Component<Props,State>{
    constructor(props:any){
        super(props);
        this.state={
            loader:false,
            isDandi:false,
            first_name:'',
            last_name:'',
            email:'',
            password:'',
            errors: {
                first_name:'',
                last_name:'',
                email: '',
                password: '',
                
              }
        };
    }
    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
          // if we have an error string set valid to false
          (val:any) => val.length > 0 && (valid = false),
        );
        return valid;
      }
   async Signup(e){

         e.preventDefault();
         const {email, password,first_name,last_name,isDandi} = this.state;
         const User:any = [{Email:email, Password:password}];
        if(this.validateForm(this.state.errors) && email!=='' && password!=='') {
            this.setState({loader:true});
            let init:any = {
                method: 'POST',
                headers: { 'content-type': 'multipart/form-data',
                            'Accept': 'application/json'},
                mode: 'cors',
                cache: 'default',
                dataType: 'json',
                async:    true,
              };
             
            let formData = new FormData();
            formData.append('firstname',first_name);
            formData.append('lastname',last_name);
            formData.append('email',email);
            formData.append('password',password);
            formData.append('is_dandi',isDandi?"1":"0");
        
          await axios.post("https://api.cyruns.in/api/athletes.php?action=registration", formData,init)
            .then(response => {
              //  console.log(response);
              this.setState({loader:false});
              if(response.data.msgcode == 0){
                  toast.success(response.data.msg);
                 // const encryptUser = cryptoJSON.encrypt(response.data.user,'monkdesk@2019');
                //  sessionStorage.setItem('User', JSON.stringify(encryptUser));
               // cookie.save('User', response.data.user, { path: '/' , maxAge: 43200});
                  this.props.history.push({ 
                      pathname: '/user-detailform',
                      state:{msg: response.data.msg,User: response.data.user}
                     });
                window.location.reload();
              }
              else{
                toast.error(response.data.msg);
              }
            })
            .catch(error => {
                const Msg='Something went to wrong please Try later... OR Check your internet connection';
                  toast.error(Msg);
                   this.setState({loader:false});
                  console.log("Errors"+error);
             });           
        }else{
          toast.error("form is not valid !");
        }
    }
    onInputChange(e: any) {
        e.preventDefault();
        const { name, value } = e.target;
        let errors = this.state.errors;
        const validEmailRegex = 
            RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        switch (name) {
            case 'first_name':
                errors.first_name = 
                value.length < 1
                ? 'Fist Name must be required!'
                : '';
            case 'last_name':
                errors.first_name = 
                value.length < 1
                ? 'last Name must be required!'
                : '';

            case 'email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : 'Email is not valid!';
            break;
          case 'password': 
            errors.password = 
              value.length < 8
                ? 'Password must be 8 characters long!'
                : '';
            break;
          default:
            break;
        }
        this.setState({errors, [name]: value}, ()=> {
            //console.log(errors)
        })
    }
    dandiChange=(e)=>{
       this.setState({isDandi: !this.state.isDandi});
    }
    render(){
        const {loader,errors} =this.state;
        return (
            <div>
               <div>
                <div className="container" >
                <ToastContainer/>
                    <form className='login_form signup_form'>
                        <div className='log_header'>
                            <a href="#"><img src='Images/runner.svg'/></a>
                            <h3>Signup to athletes</h3>
                        </div>
                        <div className='login_fcont'>
                            <div className="form-group">
                                <Input className='log_input' name="first_name" label="First Name" type='text' floatingLabel={true} onChange={(e: any) => this.onInputChange(e)} />
                                {errors.first_name.length > 0 && 
                                        <span className='error_msg'>{errors.first_name}</span>}
                                <Input className='log_input' name="last_name" label="Last Name" type='text' floatingLabel={true} onChange={(e: any) => this.onInputChange(e)} />
                                {errors.last_name.length > 0 && 
                                        <span className='error_msg'>{errors.last_name}</span>}
                                <Input className='log_input' name="email" label="Email" type='text' floatingLabel={true} onChange={(e: any) => this.onInputChange(e)} />
                                {errors.email.length > 0 && 
                                    <span className='error_msg'>{errors.email}</span>}
                                <Input className='log_input' name="password" label="Password" type='password' floatingLabel={true} onChange={(e: any) => this.onInputChange(e)} />
                                {errors.password.length > 0 && 
                                        <span className='error_msg'>{errors.password}</span>}
                            </div>
                            {/* <div>
                                <input type="checkbox"  onChange={this.dandiChange}/>
                                <label>dandi march ultra challenge participant</label>
                            </div> */}
                             {/*  <div className="form-check">
                               
                                <label className="form-check-label" >
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                onChange={this.dandiChange}/>
                                    Navratri 2021 Challenge Participant
                                </label>
                            </div>*/}
                        </div>
                        {/* <div className="form-check" style={{textAlign:'left',fontSize:20}}>
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" style={{cursor:'pointer'}}/>
                                <label className="form-check-label" >I accept the 
                                    <a href="#"> Terms &amp; Conditions.</a>
                                </label>
                        </div> */}
                        <div className='log_btn'>
                            {!loader ?
                            <Button variant="raised" onClick={this.Signup.bind(this)}>Signup</Button> :
                            <button className="" type="button" disabled>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading...
                            </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
            </div>
        );
    }

}

export default Signup;