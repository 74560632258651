import * as React from 'react';
import axios from 'axios';
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import {UserData} from '../services/UserData';
import cookie from 'react-cookies';
import { AsyncStorageService } from '../services/AsyncStorage';

interface Props {
    history:any;
}
interface State {
    userImage: any;
    user:any;
    result:any;
}
class ScreenHeader extends React.Component<Props,State>{
    constructor(props:any){
        super(props);
        this.state ={
            userImage:'',
            user:[],
            result:{}
        }
    }

    componentDidMount=async()=>{
        this.getUserAccess();
        const data = await UserData.getUserData();
        const pro = data.profile[0];
        const userImage = data.userImage;
        this.setState({userImage,user:pro});
    }

    getUserAccess=async()=>{
        const user = await AsyncStorageService.getUser();
        const userId = user.user_id;
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('user_id', userId);

        await axios.post("https://api.cyruns.in/api/athletes.php?action=checkusers", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    const result = response.data.result;
                    this.setState({result});
                }
        })
    }
   

    LogOut=async()=>{
        await UserData.clearUser();
        cookie.remove('is_strava_user',{ path: '/' });
        this.props.history.push('/');
        window.location.reload();
    }

    showMenu=async()=>{
        if(document.body.classList.contains('show-menu')){
            document.body.classList.remove('show-menu');
        }else{
            document.body.classList.add('show-menu');
        }

    }

    render() {
        const {userImage,user,result} = this.state;
        return (
          
                <header className='dashb_header'>
                    <div className="mobile-menu"><a href="#" onClick={this.showMenu.bind(this)}><i className="fa fa-bars" aria-hidden="true"></i></a></div>
                    <div className='home_header_logo'>
                       <a href="/my-workout"><img src='../Images/cyruns-logo.png' /></a> 
                    </div>
                    <div className="dash_menu">
                        {/* <div className="main_menu">
                            <ul>
                                <li><a href="/dashboard">Dashboard<i className="fa fa-angle-down"/></a>
                                    <ul className="dash_submenu">
                                        <li><a href="#">Option1</a></li>
                                        <li><a href="#">Option2</a></li>
                                        <li><a href="#">Option3</a></li>
                                        <li><a href="#">Option4</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div> */}
                        <div className="main_menu">
                            <ul>
                                <li>
                                    <a href="#">Workout
                                        <i className="fa fa-angle-down"/>
                                    </a>
                                    <ul className="dash_submenu">
                                         <li><a href="/my-workout">My Workout</a></li>
                                         <li><a href="/add-manual">Add Workout</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        {
                            result.is_both == 1 || result.is_resolution == 1?
                                <div className="main_menu">
                                    <ul>
                                        <li>
                                            <a href="/leader-board">Leadership Board
                                                {/* <i className="fa fa-angle-down"/> */}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            :
                            null
                        }
                        {
                        result.is_both == 1 || result.is_resolution == 1?
                        <div className="main_menu">
                            <ul>
                                <li>
                                    <a href="#">Previous Certificates
                                        <i className="fa fa-angle-down"/>
                                    </a>
                                    <ul className="dash_submenu">
                                        <li><a href="/certificate2022">Certificate 2022</a></li>
                                        <li><a href="/certificate2023">Certificate 2023</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                         :
                         null
                         }
                          {
                        result.is_both == 1 || result.is_resolution == 1?
                        <div className="main_menu">
                            <ul>
                                <li>
                                    <a href="/certificatecurrent">Current Certificate
                                    </a>
                                </li>
                            </ul>
                        </div>
                         :
                         null
                         }

                        {
                        result.in_ayodhya_run == '1' ?
                        <div className="main_menu">
                            <ul>
                                <li>
                                    <a href="/ayodhyarunwalk">Ayodhya Run
                                    </a>
                                </li>
                            </ul>
                        </div>
                        : 
                        null
                        }  

                          {/*
                        result.is_both == 1 || result.is_dandi == 1?
                        <div className="main_menu">
                            <ul>
                                <li>
                                    <a href="/dandi">Dandi sainik
                                    </a>
                                </li>
                            </ul>
                        </div>
                           :
                           null
                           }
                           {
                        result.is_navratri == 1?
                        <div className="main_menu">
                            <ul>
                                <li>
                                    <a href="/navratri">Navratri Leaderboard
                                    </a>
                                </li>
                            </ul>
                        </div>
                           :
                           null
                          */ }
                        {/* <div className="main_menu">
                            <ul>
                                <li>
                                    <a href="/myreport">Reports
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                       
                        {/* <div className="main_menu">
                            <ul>
                                <li>
                                    <a href="/dashboard">Activity Wall
                                        <i className="fa fa-angle-down"/>
                                    </a>
                                    <ul className="dash_submenu">
                                        <li><a href="/dashboard">Wall Post</a></li>
                                        <li><a href="/my-activity">My Activity</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    {/* <div className='header_searc_inpt'>
                        <a href="" data-toggle="modal" data-target="#findFriend">
                            <Input placeholder="Find friends" />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </a>
                    </div> */}
                    {/* <div className='hea_notifctn_btn'>
                        <Button>
                         <i className="fa fa-bell-o" aria-hidden="true"></i>
                        </Button>
                    </div> */}
                    
                    {/* <div className='dash_add_drop'>
                      <div className="main_menu">
                            <ul>
                                <li><a href=""><i className="fa fa-plus-circle" aria-hidden="true" style={{fontSize:30}}></i></a>
                                    <ul className="dash_submenu">
                                        <li><a href="/add-manual">Add Workout</a></li>
                                        <li><a href="/create-post">Create a post</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div> */}

                    <div className='da_profmenu'>
                        <div className='menu_uname'> Welcome
                        <b> {user.first_name?user.first_name:""}</b>    
                        </div>
                      <div className="main_menu">
                            <ul>
                                <li><a href="#"><div className="post_img_ico">
                                        {userImage!=''?
                                        <img src={userImage} />
                                         :
                                        user.gender == "1"?
                                        <img src="Images/noimage.jpg" />
                                        :
                                        <img src="Images/femaleimages.png" />
                                        }
                                        </div><i className="fa fa-angle-down"/>
                                     </a>
                                    <ul className="dash_submenu dash_submenupro">
                                        <li><a href="/profile">My Profile</a></li>
                                        {/* <li><a href="#" data-toggle="modal" data-target="#findFriend">Find Friends</a></li> */}
                                        {/* <li><a href="#">Settings</a></li> */}
                                        <li><a href="/" onClick={this.LogOut.bind(this)}>Logout</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                       
                    </div>
                  
                </header>
        );
    }

}
export default ScreenHeader;