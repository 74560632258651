import * as React from 'react';
import AllRanklistModel from '../components/AllRanklistModel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import CertificateView from '../components/CertificateView';
import {AsyncStorageService} from '../services/AsyncStorage';


interface Props{

}

interface State{
    [x: number]: any; 
    dandiAll:any;
    dandiFinisher:any;
    searchAll:any;
    searchFinisher:any;
    link:any;
    userId:any;
}

class DandiDadhboard extends React.Component<Props, State>{
    constructor(props){
        super(props);
        this.state={
            dandiAll:[],
            dandiFinisher:[],
            searchAll:null,
            searchFinisher:null,
            link:'',
            userId:''
        };
    }

    componentDidMount=async()=>{
        const user =  await AsyncStorageService.getUser();
        this.setState({userId:user.user_id});
        const date = new Date();
        const currentYear:any = date.getFullYear();
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('year', currentYear);

        await axios.post("https://api.cyruns.in/api/athletes.php?action=dandileadership", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    const dandiAll = response.data.dandiall;
                    const dandiFinisher  = response.data.finishers;

                    this.setState({
                        dandiAll,dandiFinisher
                    });
                } else {
                    toast.error(response.data.msg)
                }
            })
    }
    searchSpace=(event)=>{
        let {value,name} = event.target;
        this.setState({[name]:value});
      }
    render(){
        const {dandiAll,dandiFinisher,searchAll,searchFinisher,link,userId} = this.state;
        let item:any;
        let finish:any;
        if(dandiAll){
            const items = dandiAll.filter((data)=>{
                if(this.state.searchAll == null)
                    return data
                else if(data.first_name.toLowerCase().includes(searchAll.toLowerCase()) || data.last_name.toLowerCase().includes(searchAll.toLowerCase()) || 
                        data.rank.toString().includes(searchAll.toLowerCase()) || data.distance.toLowerCase().includes(searchAll.toLowerCase())){
                    return data
                }
              }).map((data,index)=>{
                    return(
                        <li key={index}>
                          <p>
                              <span className="span_number">{data.rank}. </span>
                              <span className="span_name">
                                  <a>{data.first_name+' '+data.last_name}</a>
                              </span>
                              {userId == data.user_id?
                                <span className="rank_in certntn_dandi">
                                    <a href={data.cer_link} target='blank'>Certificate</a>  
                                    {/* {data.distance} */}
                                 </span>
                                 :
                                //  <span className="rank_in certntn_dandi disable_Certi">
                                //      <a href="#" >Finisher</a>
                                //  </span>
                                <span className="rank_in">{data.distance}</span>
                              }
                             
                          </p>
                      </li>
                      )
              })
              item = items;
        }
        if(dandiFinisher){
            const items = dandiFinisher.filter((data)=>{
                if(this.state.searchFinisher == null)
                    return data
                else if(data.first_name.toLowerCase().includes(searchFinisher.toLowerCase()) || data.last_name.toLowerCase().includes(searchFinisher.toLowerCase()) || 
                        data.rank.toString().includes(searchFinisher.toLowerCase()) || data.distance.toLowerCase().includes(searchFinisher.toLowerCase())){
                    return data
                }
              }).map((data,index)=>{
                    return(
                        <li key={index}>
                          <p>
                              <span className="span_number">{data.rank}. </span>
                              <span className="span_name">
                                  <a>{data.first_name+' '+data.last_name}</a>
                              </span>
                              {userId == data.user_id?
                                <span className="rank_in certntn_dandi">
                                    <a href={data.cer_link} target='blank'>Certificate</a>  
                                    {/* {data.distance} */}
                                 </span>
                                 :
                                 <span className="rank_in certntn_dandi disable_Certi">
                                     <a href="#" >Finisher</a>
                                 </span>
                              }
                          </p>
                      </li>
                      )
              })
              finish = items;
        }
        return(
            <div className='container leader_main'>
                 <ToastContainer />
                 <a href="#" className="btn stage_btn" id='showCertificateModel'
                    data-toggle="modal" data-target="#modal-fullscreen" style={{ display: 'none' }}></a>
                <div className='dandi_img'>
                <img src="./Images/Dandi_March_LOGO.png" />
                </div>
                 <div className="row">
                    <div className="col-md-12">
                        <div className="leader_title">
                            <div className="heading">
                                <h3>Dandi March Ultra Challenge</h3>
                            </div>
                            <div className="sub_title">
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className='leader_content'>
                    <div className='row'>
                        <div className='col-md-6'>
                        <div className="ranking_box">
                            <div className="rank_title first_2">
                                    <div className="title_inner">
                                        <div className="rank_title_name dandip_title">
                                            <h3>Participant</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ranking_list_dandi">
                                    <div className="top_five_list all_runner_data" data-year="2020">
                                        <input type="text" name="searchAll" id="keyword" className="form-control" placeholder="Search Your Name And Get Certificate"
                                            style={{ textAlign: 'center', marginBottom: 10 }} onChange={this.searchSpace}/>

                                        <ul className="unordered-list text-left leadershipboard" id="lsboard">
                                                {dandiAll !=''
                                                ?item:
                                                <li className="no_data">
                                                    No data available
                                                </li>
                                               }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="ranking_box">
                              <div className="rank_title first_2">
                                    <div className="title_inner">
                                        <div className="rank_title_name dandifin_title">
                                            <h3>Finishers</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ranking_list_dandi">
                                    <div className="top_five_list all_runner_data" data-year="2020">
                                        <input type="text" name="searchFinisher" id="keyword" className="form-control" placeholder="Search Your Name And Get Certificate"
                                            style={{ textAlign: 'center', marginBottom: 10 }} onChange={this.searchSpace}/>

                                        <ul className="unordered-list text-left leadershipboard" id="lsboard">
                                                {dandiFinisher !=''
                                                ?finish:
                                                <li className="no_data">
                                                    No data available
                                                </li>
                                                }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    {/* dandi certificate */}
                <MediaQuery minDeviceWidth={1224} >
                    <div className="modal fade modal-fullscreen" id="modal-fullscreen" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered  fullscreenModel">
                            <CertificateView  Link={link} dandi={1}/>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        )
    }
}

export default DandiDadhboard;