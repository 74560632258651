import * as React from 'react';
import AllRanklistModel from '../components/AllRanklistModel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

interface Props {

}
interface State {
    currentYear: any;
    fYear: any;
    icon: any;
    title: any;
    Vadata: any;
    top5man: any;
    top5woman: any;
    top5cycling: any;
    top5running: any;
    top5walking: any;
    top5stairs:any;
    allOver: any;
    search:any;
}
class LeadershipBoard extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
            currentYear: '',
            fYear: '',
            icon: '',
            title: '',
            Vadata: [],
            top5man: [],
            top5woman: [],
            top5cycling: [],
            top5running: [],
            top5walking: [],
            top5stairs:[],
            allOver: [],
            search:null
        }
    }
    componentDidMount() {
        const date = new Date();
        const currentYear = date.getFullYear();
        this.setState({ currentYear, fYear: currentYear });
        this.top5Leadership(currentYear);
        this.alloverleardership(currentYear);
    }

    top5Leadership = async (Year) => {
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('year', Year);
        formData.append('gender_type', 'all');
        await axios.post("https://api.cyruns.in/api/athletes.php?action=top5leadershipboard", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    this.setState({
                        top5man: response.data.top_5_men,
                        top5woman: response.data.top_5_women,
                        top5cycling: response.data.top_5_cyclist,
                        top5running: response.data.top_5_runners,
                        top5walking: response.data.top_5_walking,
                        top5stairs:response.data.top_5_stairs
                    });
                } else {
                    toast.error(response.data.msg)
                }
            })
    }
    alloverleardership = async (Year) => {
        this.setState({fYear:Year});
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('year', Year);

        await axios.post("https://api.cyruns.in/api/athletes.php?action=alloverleardership", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    this.setState({
                        allOver: response.data.top_all
                    });
                } else {
                    this.setState({
                        allOver: response.data.top_all
                    });
                    toast.error(response.data.msg)
                }
            })
    }
    searchSpace=(event)=>{
        let keyword = event.target.value;
        this.setState({search:keyword})
      }
    viewAll=async(gender,type)=>{
        const {fYear} = this.state;
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('year', fYear);
        formData.append('gender', gender);
        formData.append('type', type);

        await axios.post("https://api.cyruns.in/api/athletes.php?action=top5alldata", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    this.setState({
                        Vadata: response.data.view_all
                    });
                } else {
                    toast.error(response.data.msg)
                }
            })
    }
    

    render() {
        const { currentYear, top5man, top5woman, top5cycling, top5running, top5walking,
             allOver,fYear,top5stairs } = this.state;
             let item:any;
            if(allOver){
                const items = allOver.filter((data)=>{
                    if(this.state.search == null)
                        return data
                    else if(data.first_name.toLowerCase().includes(this.state.search.toLowerCase()) || data.last_name.toLowerCase().includes(this.state.search.toLowerCase()) || 
                            data.rank.toString().includes(this.state.search.toLowerCase()) || data.distance.toLowerCase().includes(this.state.search.toLowerCase())){
                        return data
                    }
                  }).map((data,index)=>{
                      if(index < 100){
                        return(
                            <li key={index}>
                              <p>
                                  <span className="span_number">{data.rank}. </span>
                                  <span className="span_name">
                                      <a>{data.first_name+' '+data.last_name}</a>
                                  </span>
                                  <span className="rank_in">{data.distance}</span>
                              </p>
                          </li>
                          )
                      }
                  })
                  item = items;
            }
        return (
            <div className='container leader_main'>
                <ToastContainer />
                <div className="row">
                    <div className="col-md-12">
                        <div className="leader_title">
                            <div className="heading">
                                <h3>Our Top Athletes</h3>
                            </div>
                            <div className="sub_title">
                            </div>
                        </div>
                    </div>
                </div>
                <div className='leader_content'>
                    <div className='row'>
                        {/* Top 5 Men */}
                        <div className='col-md-3'>
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img">
                                        <div className='rank_img_man'>
                                            <img src="./Images/boy winner.svg" />
                                        </div>
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_1">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Top 5 Men</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list">
                                                <ul>
                                                    {top5man != '' && top5man != null ?
                                                        top5man.map((man, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{man.first_name + ' ' + man.last_name}</span>
                                                                    <span className="rank_in">{man.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ""}

                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_5_men_all show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "boy winner.svg", title: "Men" }); this.viewAll(1,'') }}>
                                                View Top 100 Men List
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* All our board */}
                        <div className='col-md-6'>
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img resolution">
                                        <img src="./Images/athlet_leaderboard.svg" />
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_2">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Resolution {fYear} Leaderboard</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list all_runner_data" data-year="2020">
                                                <input type="text" name="search" id="keyword" className="form-control" placeholder="Search Your Rank"
                                                    style={{ textAlign: 'center', marginBottom: 10 }} onChange={this.searchSpace}/>

                                                <ul className="unordered-list text-left leadershipboard" id="lsboard">
                                                        {allOver?item:""}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Top 5 Women */}
                        <div className='col-md-3'>
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img">
                                        <div className='rank_img_women'>
                                            <img src="/Images/girl winner.svg" />
                                        </div>
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_3">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Top 5 Women </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list">
                                                <ul>
                                                    {top5woman != '' && top5woman != null ?
                                                        top5woman.map((woman, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{woman.first_name + ' ' + woman.last_name}</span>
                                                                    <span className="rank_in">{woman.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ""}


                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_5_women_all show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "girl winner.svg", title: "Women" }) ; this.viewAll(2,'') }}>
                                                    View Top 100 Women List</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        {/* Cyclists */}
                        <div className="col-12 col-md-4">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img">
                                        <div className="rank_img_cycle">
                                            <img src="./Images/cyruns_cyclist.svg" />
                                        </div>
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_4">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Cycling <span className="t5_type"></span>Leaderboard </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_cyc">
                                                <ul>
                                                    {top5cycling != '' && top5cycling != null ?
                                                        top5cycling.map((cycling, index) => {
                                                    return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{cycling.first_name + ' ' + cycling.last_name}</span>
                                                                    <span className="rank_in">{cycling.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ''}


                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "cyruns_cyclist.svg", title: "Cyclist" }) ; this.viewAll('',2) }}>
                                                    View Top 100 Cyclist</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Runners */}
                        <div className="col-12 col-md-4">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img">
                                        <div className="rank_img_run">
                                            <img src="./Images/cyruns_runner.svg" />
                                        </div>
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_5">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Running <span className="t5_type"></span>Leaderboard </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_run">
                                                <ul>
                                                    {top5running != '' && top5running != null ?
                                                        top5running.map((run, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{run.first_name + ' ' + run.last_name}</span>
                                                                    <span className="rank_in">{run.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ''}

                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "cyruns_runner.svg", title: "Runners" });this.viewAll('',1) }}>
                                                    View Top 100 Runners</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Walking */}
                        <div className="col-12 col-md-4">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img">
                                        <div className="rank_img_walking">
                                            <img src="Images/cyruns_walking.svg" />
                                        </div>
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_6">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Walking <span className="t5_type"></span>Leaderboard </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_walk">
                                                <ul>
                                                    {top5walking != '' && top5walking != null ?
                                                        top5walking.map((walk, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{walk.first_name + ' ' + walk.last_name}</span>
                                                                    <span className="rank_in">{walk.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ''}
                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "cyruns_walking.svg", title: "Walking" }) ;this.viewAll('',3)}}>
                                                    View Top 100 Walking</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

{/* new Additional */}
        <div className='row'>
                        {/* Stairs */}
                        <div className="col-12 col-md-6">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img">
                                        <div className="rank_img_cycle">
                                            <img src="./Images/stairs.svg" />
                                        </div>
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_4">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Climbers <span className="t5_type"></span>Leaderboard </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_cyc">
                                                <ul>
                                                    {top5stairs != '' && top5stairs != null ?
                                                        top5stairs.map((stairs, index) => {
                                                    return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{stairs.first_name + ' ' + stairs.last_name}</span>
                                                                    <span className="rank_in">{stairs.stairs +` (`+stairs.days + ' Days)'}</span>
                                                                    {/* <span className="days_count">{stairs.days + ' days'}</span> */}
                                                                </p>
                                                            </li>
                                                        })
                                                        : ''}
                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "stairs.svg", title: "Climbers" }) ; this.viewAll('',5) }}>
                                                    View Top 100 Climbers</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Steps */}
                        {/* <div className="col-12 col-md-4">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img">
                                        <div className="rank_img_run">
                                            <img src="./Images/steps.svg" />
                                        </div>
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_5">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Steps <span className="t5_type"></span>Leaderboard </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_run">
                                                <ul>
                                                    {top5running != '' && top5running != null ?
                                                        top5running.map((run, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{run.first_name + ' ' + run.last_name}</span>
                                                                    <span className="rank_in">{run.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ''}

                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "steps.svg", title: "Steps" });this.viewAll('',6) }}>
                                                    View Top 100 Steps</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        // Exercise 
                        <div className="col-12 col-md-4">
                            <div className="top_rank">
                                <div className="top_rank_inner">
                                    <div className="rank_img">
                                        <div className="rank_img_walking">
                                            <img src="Images/exercise.svg" />
                                        </div>
                                    </div>
                                    <div className="ranking_box">
                                        <div className="rank_title first_6">
                                            <div className="title_inner">
                                                <div className="rank_title_name">
                                                    <h3>Exercise <span className="t5_type"></span>Leaderboard </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ranking_list">
                                            <div className="top_five_list t5_block" id="t5_walk">
                                                <ul>
                                                    {top5walking != '' && top5walking != null ?
                                                        top5walking.map((walk, index) => {
                                                            return <li key={index}>
                                                                <p>
                                                                    <span className="span_number">{index + 1}. </span>
                                                                    <span className="span_name">{walk.first_name + ' ' + walk.last_name}</span>
                                                                    <span className="rank_in">{walk.distance}</span>
                                                                </p>
                                                            </li>
                                                        })
                                                        : ''}
                                                    <div className="clr"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="all_list_block">
                                            <button type="button" className="btn btn-secondary top_all_type show-all-list" data-toggle="modal" data-target="#allRanklist"
                                                onClick={() => { this.setState({ icon: "exercise.svg", title: "Exercise" }) ;this.viewAll('',7)}}>
                                                    View Top 100 Exercise</button>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>*/}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="past_section">
                                <div className="shadow_past">
                                    <div className="past_year_title">
                                        <h1>See Year Wise Leaderboard</h1>
                                    </div>
                                    <div className="text-center">
                                        <div className="past_year" onClick={() => { this.top5Leadership(currentYear - 1); this.alloverleardership(currentYear - 1) }}>
                                            <a className={fYear == currentYear - 1?"btn btn_year l_year_active":"btn btn_year"} >Year {currentYear - 1}</a><br />
                                        </div>
                                        <div className="past_year" onClick={() => { this.top5Leadership(currentYear); this.alloverleardership(currentYear) }}>
                                            <a className={fYear == currentYear ?"btn btn_year l_year_active":"btn btn_year"} >Year {currentYear}</a><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* AllRanklistModel */}
                <div className="modal fade show" id="allRanklist" role="dialog" >
                    <div className="modal-dialog modal-dialog-centered" >
                        <AllRanklistModel icon={this.state.icon} title={this.state.title}
                            alldata={this.state.Vadata} />
                    </div>
                </div>


            </div>
        )
    }
}

export default LeadershipBoard;