import * as React from 'react';
import axios from 'axios';
import MultiStep from 'react-multistep';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import Button from 'muicss/lib/react/button';
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import { AsyncStorageService } from '../services/AsyncStorage';
import Loader from 'react-loader-spinner';

import { UserData } from '../services/UserData';

interface Props {
    history: any;
}
interface State {
    [x: number]: any;
    loader: boolean;
    country: any;
    region: any;
    contactNumber: any;
    whatsappNumber: any;
    dateofbirth: any;
    groupname: any;
    address1: any;
    address2: any;
    city: any;
    pincode: any;
    gender: any;
    shoesBrand: any;
    gpsWatch: any;
    hydration: any;
    medicalHistory: any;
    bloodGroup: any;
    tshirtSize: any;
    fbLink: any;
    instaLink: any;
    emergencyContactName: any;
    emergencyContactNumber: any;
    Photo: any;
    allergies: any;
    allergieName: any;
    cityList: any;
    userImage: any;
    f_Name:any;
    l_Name:any;
}
class editProfile extends React.Component<Props, State>{
    dropzoneRef: any = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            country: '',
            region: '',
            contactNumber: '',
            whatsappNumber: '',
            dateofbirth: '',
            groupname: '',
            address1: '',
            address2: '',
            city: '',
            pincode: '',
            gender: '',
            shoesBrand: '',
            gpsWatch: '',
            hydration: '',
            medicalHistory: '',
            bloodGroup: '',
            tshirtSize: '',
            fbLink: '',
            instaLink: '',
            emergencyContactName: '',
            emergencyContactNumber: '',
            Photo: null,
            allergies: '',
            allergieName: '',
            loader: false,
            cityList: [],
            userImage: '',
            f_Name:'',
            l_Name:''
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount = async () => {
        const data = await UserData.getUserData();
        const pro = data.profile[0];
        const userImage = data.userImage;
        // console.log(pro);
        this.setState({
            userImage, contactNumber: pro.contact_number,
            whatsappNumber: pro.whatsup_number,
            dateofbirth: new Date(pro.dob), groupname: pro.club_name,
            address1: pro.address_line1, address2: pro.address_line2,
            country: pro.country, region: pro.state, city: pro.city,
            pincode: pro.pincode, gender: pro.gender, 
            shoesBrand: pro.shoes_brand!=null?pro.shoes_brand:'',
            gpsWatch: pro.gps_watch != null?pro.gps_watch:'',
            hydration: pro.preferred_supplement != null?pro.preferred_supplement:'',
            medicalHistory: pro.medical_history != null?pro.medical_history:'', 
            bloodGroup: pro.blood_group !=null?pro.blood_group:'',
            tshirtSize: pro.tshirt_size != null?pro.tshirt_size:'',
            fbLink: pro.fb_link !=null ? pro.fb_link:'', 
            instaLink: pro.insta_link !=null? pro.insta_link:'',
            emergencyContactName: pro.emergency_contact_name !=null?pro.emergency_contact_name:'', 
            emergencyContactNumber: pro.emergency_contact_no !=null?pro.emergency_contact_no:'',
            allergieName: pro.allergies !=null ? pro.allergies:'',
            f_Name: pro.first_name != null?pro.first_name:'' ,
            l_Name: pro.last_name !=null? pro.last_name:''
        });
        if (pro.allergies != "") {
            this.setState({ allergies: 'yes' })
        }
        this.findCity(pro.state);
    }

    findCity = async (state) => {
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        let formData = new FormData();

        formData.append('state_name', state);
        await axios.post("https://api.cyruns.in/api/athletes.php?action=citylist", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    this.setState({ cityList: response.data.city })
                }
            })
    }

    selectCountry(val) {
        this.setState({ country: val });
    }
    selectRegion(val) {
        this.setState({ region: val });
        this.findCity(val);
    }
    SkipForm() {
        this.props.history.push('/my-workout');
        window.location.reload(false);
    }
    onInputChange = (e: any) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    getPhoto(file: any) {

        if (file.length == 1) {
            if (file[0].type == 'image/jpeg' || file[0].type == 'image/jpg' || file[0].type == 'image/png') {
                this.setState({
                    Photo: file[0],
                });
            } else {
                toast.error("upload must be an jpg/jpeg/png image");
            }

        } else {
            toast.error("You have to upload only 1 image!");
        }
    }
    imageRemove() {
        this.setState({ Photo: null });
    }
    async SubmitForm(e) {
        e.preventDefault();
        this.setState({ loader: true });
        const { Photo, country, region, contactNumber, whatsappNumber, emergencyContactNumber,
            dateofbirth, groupname, address1, address2, city, pincode, gender, shoesBrand, allergieName,
            gpsWatch, hydration, medicalHistory, bloodGroup, tshirtSize, fbLink, instaLink,
             emergencyContactName,f_Name,l_Name } = this.state;
        const user = await AsyncStorageService.getUser();
        const userId = user.user_id;

        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };

        if (contactNumber != '' && whatsappNumber != '' && dateofbirth != '' && address1 != '' &&
            country != '' && region != '' && city != '' && pincode != '' && gender != '' &&
            f_Name!='' && l_Name!='') {
            const newdate = dateofbirth.getFullYear() + '-' + (dateofbirth.getMonth() + 1) + '-' + dateofbirth.getDate();

            let formData = new FormData();
            formData.append('user_id', userId);
            formData.append('f_name',f_Name);
            formData.append('l_name',l_Name);

            formData.append('contact_no', contactNumber);
            formData.append('whats_no', whatsappNumber);
            formData.append('dob', newdate);
            formData.append('club_name', groupname);
            formData.append('addressline1', address1);
            formData.append('addressline2', address2);

            formData.append('city', city);
            formData.append('state', region);
            formData.append('country', country);
            formData.append('pincode', pincode);
            formData.append('gender', gender);

            formData.append('shoes_brand', shoesBrand);
            formData.append('gps_watch', gpsWatch);
            formData.append('supplement', hydration);
            formData.append('medical_history', medicalHistory);

            formData.append('blood_group', bloodGroup);
            formData.append('t_shirt', tshirtSize);
            formData.append('emergency_name', emergencyContactName);
            formData.append('emergency_number', emergencyContactNumber);
            formData.append('fb_link', fbLink);
            formData.append('inst_link', instaLink);
            formData.append('allergies', allergieName);
            formData.append('photos', Photo);

            await axios.post("https://api.cyruns.in/api/athletes.php?action=editprofile", formData, init)
                .then(response => {
                    console.log(response.data);
                    this.setState({ loader: false });
                    if (response.data.msgcode == 0) {
                        toast.success(response.data.msg);
                        this.props.history.push({
                            pathname: '/profile',
                            state: response.data.msg
                        });
                        window.location.reload(false);
                    }
                    else {
                        toast.error(response.data.msg);
                    }
                })
                .catch(error => {
                    const Msg = 'Something went to wrong please Try later... OR Check your internet connection';
                    toast.error(Msg);
                    this.setState({ loader: false });
                    console.log(error);
                });
        } else {
            toast.error('Please fill all *required fields');
        }



    }
    DatePickers = (dateofbirth) => {
        this.setState({ dateofbirth });
    }
    stepOne = () => {
        return (
            <form>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="f_Name">First Name<span className="required_text">*</span></label>
                        <input type="text" className="form-control" name="f_Name" id="f_Name" placeholder="First Name"
                            value={this.state.f_Name} onChange={(e) => this.onInputChange(e)} autoComplete="off"/>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="l_Name">Last Name<span className="required_text">*</span></label>
                        <input type="text" className="form-control" name='l_Name' id="l_Name" placeholder="Last Name"
                            value={this.state.l_Name} onChange={(e) => this.onInputChange(e)} autoComplete="off"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="contactNumber">Contact Number<span className="required_text">*</span></label>
                        <PhoneInput
                            inputClass='form-control'
                            country={'in'}
                            enableSearch={true}
                            inputProps={{
                                name: 'contactNumber',
                            }}
                            containerStyle={{ marginBottom: '1rem' }}
                            onChange={(contactNumber) => this.setState({ contactNumber })}
                            value={this.state.contactNumber}
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="whatsappNumber">Whatsapp Number<span className="required_text">*</span></label>
                        <PhoneInput
                            inputClass='form-control'
                            country={'in'}
                            enableSearch={true}
                            inputProps={{
                                name: 'whatsappNumber',
                            }}
                            onChange={(whatsappNumber) => this.setState({ whatsappNumber })}
                            value={this.state.whatsappNumber} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="dateofbirth">Date of Birth<span className="required_text">*</span></label>

                        <div className="form-group form-inline dateNtime" >
                            <DatePicker
                                onChange={this.DatePickers}
                                value={this.state.dateofbirth}
                                format={'dd-MM-y'}
                                dayPlaceholder={'dd'}
                                monthPlaceholder={'mm'}
                                yearPlaceholder={'yyyy'}
                                returnValue={'start'}
                                className={"datepicker"}
                                minDate={new Date("1-1-1900")}
                            />
                        </div>

                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="clubName">Group Name (optional)</label>
                        <input type="text" id='clubName' className="form-control"
                            placeholder='Group / Club Name' name='groupname'
                            value={this.state.groupname} onChange={(e) => this.onInputChange(e)} />
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="address1">Address Line 1<span className="required_text">*</span></label>
                    <input type="text" className="form-control" value={this.state.address1} name="address1" id="address1" placeholder=" Flat Number / Building Name or Number"
                        onChange={(e) => this.onInputChange(e)} />
                </div>
                <div className="form-group">
                    <label htmlFor="address2">Address Line 2</label>
                    <input type="text" className="form-control" name='address2' id="address2" placeholder="Street / Road Name / Locality"
                        value={this.state.address2} onChange={(e) => this.onInputChange(e)} />
                </div>
                <div className="form-row">
                    <div className="form-group col-md-3">
                        <label htmlFor="inputCountry">Country<span className="required_text">*</span></label>
                        <CountryDropdown id='inputCountry' classes="form-control"
                            value={this.state.country}
                            onChange={(val) => this.selectCountry(val)}
                        />

                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="state">State<span className="required_text">*</span></label>
                        <RegionDropdown
                            id='state' classes="form-control"
                            country={this.state.country}
                            value={this.state.region}
                            onChange={(val) => this.selectRegion(val)}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <label htmlFor="inputCity">District<span className="required_text">*</span></label>
                        {this.state.country == 'India' ?
                            <select id="city" className="form-control"
                                onChange={this.onInputChange} name="city">
                                <option value=''>Choose...</option>
                                {this.state.cityList.map((city, index) => {
                                    return <option value={city} key={index}
                                        selected={city == this.state.city ? true : false}>{city}</option>
                                })}

                            </select>
                            :
                            <input type="text" name='city' className="form-control" id="inputCity" placeholder="District"
                                value={this.state.city} onChange={(e) => this.onInputChange(e)} />
                        }


                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="inputZip">Pin Code<span className="required_text">*</span></label>
                        <input type="text" name='pincode' className="form-control" id="inputZip" placeholder="Pin Code / Zip Code"
                            value={this.state.pincode} onChange={(e) => this.onInputChange(e)} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="male">Gender<span className="required_text">*</span></label>
                    <div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value='1' name="gender" id="male"
                                onChange={(e) => this.onInputChange(e)} checked={this.state.gender == '1' ? true : false} />
                            <label className="form-check-label" htmlFor="male" >Male</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="gender" value='2' id="female"
                                onChange={(e) => this.onInputChange(e)} checked={this.state.gender == '2' ? true : false} />
                            <label className="form-check-label" htmlFor="female" >Female</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="gender" value='3' id="other"
                                onChange={(e) => this.onInputChange(e)} checked={this.state.gender == '3' ? true : false} />
                            <label className="form-check-label" htmlFor="other">Other</label>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    stepTwo = () => {
        return (
            <form>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="shoesBrand">Shoes Brand and Model</label>
                        <input type="text" className="form-control" name="shoesBrand" id="shoesBrand" placeholder="Shoes Brand and Model"
                            value={this.state.shoesBrand} onChange={(e) => this.onInputChange(e)} />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="gpsWatch">GPS Watch and Model</label>
                        <input type="text" className="form-control" name='gpsWatch' id="gpsWatch" placeholder="GPS Watch and Model"
                            value={this.state.gpsWatch} onChange={(e) => this.onInputChange(e)} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="hydration">Preferred Hydration Supplement</label>
                        <input type="text" className="form-control" name='hydration' id="hydration" placeholder="Preferred Hydration Supplement"
                            value={this.state.hydration} onChange={(e) => this.onInputChange(e)} />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="medicalHistory">Medical History</label>
                        <select id="medicalHistory" name="medicalHistory" className="form-control"
                            value={this.state.medicalHistory} onChange={(e) => this.onInputChange(e)}>
                            <option value=''>Choose...</option>
                            <option value='Heart disease' selected={this.state.medicalHistory == 'Heart disease' ? true : false}>Heart disease</option>
                            <option value='Anemia' selected={this.state.medicalHistory == 'Anemia' ? true : false}>Anemia</option>
                            <option value='Hypoglycemia' selected={this.state.medicalHistory == 'Hypoglycemia' ? true : false}>Hypoglycemia</option>
                            <option value='Liver disease' selected={this.state.medicalHistory == 'Liver disease' ? true : false}>Liver disease</option>
                            <option value='Kidney disease' selected={this.state.medicalHistory == 'Kidney disease' ? true : false}>Kidney disease</option>
                            <option value='Diabetes' selected={this.state.medicalHistory == 'Diabetes' ? true : false}>Diabetes</option>
                            <option value='Pancreatic disease' selected={this.state.medicalHistory == 'Pancreatic disease' ? true : false}>Pancreatic disease</option>
                            <option value='Lactation' selected={this.state.medicalHistory == 'Lactation' ? true : false}>Lactation</option>
                            <option value='Hypertension' selected={this.state.medicalHistory == 'Hypertension' ? true : false}>Hypertension</option>
                            <option value='Other' selected={this.state.medicalHistory == 'Other' ? true : false}>Other</option>
                            <option value='None' selected={this.state.medicalHistory == 'None' ? true : false}>None</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="bloodGroup">Blood Group</label>
                        <select id="bloodGroup" name='bloodGroup' className="form-control"
                            value={this.state.bloodGroup} onChange={(e) => this.onInputChange(e)}>
                            <option value=''>Choose...</option>
                            <option value="A+">A+</option>
                            <option value="O+">O+</option>
                            <option value="B+">B+</option>
                            <option value="AB+">AB+</option>
                            <option value="A-">A-</option>
                            <option value="O-">O-</option>
                            <option value="B-">B-</option>
                            <option value="AB-">AB-</option>
                        </select>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="TShirtSize">T-Shirt Size</label>
                        <select id="TShirtSize" name="tshirtSize" className="form-control"
                            value={this.state.tshirtSize} onChange={(e) => this.onInputChange(e)}>
                            <option value=''>Choose...</option>
                            <option value="XS">XS - 36</option>
                            <option value="S">S - 38</option>
                            <option value="M">M - 40</option>
                            <option value="L">L - 42</option>
                            <option value="XL">XL - 44</option>
                            <option value="XXL">XXL - 46</option>
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="FBLink ">FB Link</label>
                        <input type="text" className="form-control" name="fbLink" id="FBLink" placeholder="FB Link"
                            value={this.state.fbLink} onChange={(e) => this.onInputChange(e)} />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="InstagramLink">Instagram Link</label>
                        <input type="text" className="form-control" name='instaLink' id="InstagramLink" placeholder="Instagram Link"
                            value={this.state.instaLink} onChange={(e) => this.onInputChange(e)} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="EmergencyContactName">Emergency Contact Name</label>
                        <input type="text" className="form-control" name='emergencyContactName' id="EmergencyContactName" placeholder="Emergency Contact Name"
                            value={this.state.emergencyContactName} onChange={(e) => this.onInputChange(e)} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="EmergencyContactNumber ">Emergency Contact Number </label>
                        <PhoneInput
                            inputClass='form-control'
                            country={'in'}
                            enableSearch={true}
                            inputProps={{
                                name: 'emergencyContactNumber'
                            }}
                            onChange={(emergencyContactNumber) => this.setState({ emergencyContactNumber })}
                            value={this.state.emergencyContactNumber}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="userPhoto">Photo</label>
                        <div className="post_imgupload" style={{ margin: 0 }} >
                            {this.state.Photo != null && this.state.Photo != '' ?
                                <div className='crp_imgdisply'>
                                    <img src={URL.createObjectURL(this.state.Photo)} />
                                    <i className="fa fa-times-circle-o" aria-hidden="true" onClick={this.imageRemove.bind(this)}></i>
                                </div>
                                :
                                this.state.userImage != "" ?
                                    <div className='crp_imgdisply'>
                                        <img src={this.state.userImage} />
                                        <i className="fa fa-times-circle-o" aria-hidden="true" onClick={()=>this.setState({userImage:''})}></i>
                                    </div>
                                    :
                                    <Dropzone ref={this.dropzoneRef} onDrop={(acceptedFiles) => this.getPhoto(acceptedFiles)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} multiple={false} />
                                                <label className="img_upldico" htmlFor='post_imgupload_inpt'>
                                                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                                                    <p>Drag and drop OR Click to upload Photo</p>
                                                </label>
                                            </div>
                                        )}
                                    </Dropzone>
                            }
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="Allergies">Any Allergies</label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" value='yes' name="allergies" id="allergies"
                                    onChange={this.onInputChange} checked={this.state.allergies === 'yes' ? true : false} />
                                <label className="form-check-label" htmlFor="Yes" >Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" value='no' name="allergies" id="allergies"
                                    onChange={this.onInputChange} checked={this.state.allergies === 'no' || this.state.allergies === '' ? true : false} />
                                <label className="form-check-label" htmlFor="No" >No</label>
                            </div>

                        </div>
                        {this.state.allergies === 'yes' ?
                            <div className="form-group">
                                <input type="text" className="form-control" name='allergieName' id="allergieName" placeholder="Allergie Name"
                                    value={this.state.allergieName} onChange={(e) => this.onInputChange(e)} />
                            </div> : ''}
                    </div>
                </div>
                {/* <div className='usrdtl_btns'>
                    <Button className='usrdtl_save_btn' type='button' onClick={this.SubmitForm.bind(this)}>Update</Button>
                </div> */}
            </form>
        );
    }

    render() {
        const { loader, country, region, contactNumber, whatsappNumber, emergencyContactNumber,
            dateofbirth, groupname, Photo } = this.state;




        const steps = [
            { name: 'StepOne', component: <this.stepOne /> },
            { name: 'StepTwo', component: <this.stepTwo /> }
        ];
        return (
            <div className='userdetal-screen'>
                <ToastContainer />
                <div className="container">
                    <div className="usrdtl_title usrdtl_title_edtpro">
                        <img src='../Images/resume.png' />
                        <p>Edit profile :)</p>
                        <div className='usrdtl_btns_updt'>
                            <Button className='usrdtl_save_btn' type='button'
                                onClick={this.SubmitForm.bind(this)}>Update</Button>
                        </div>
                    </div>

                    <MultiStep steps={steps} onStepClick={() => { alert('change') }}
                        onChange={() => { alert('change') }} onClick={() => { alert('click') }}>

                    </MultiStep>
                </div>

            </div>

        );
    }


}



export default editProfile;