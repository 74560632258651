import * as React from 'react';

import { Route, Switch, Redirect,BrowserRouter,Router} from 'react-router-dom';
import Home from '../../screens/Home';
import Login from '../../screens/Login';
import Signup from '../../screens/Signup';
import Dashboard from '../../screens/Dashboard';
import MyProfile from '../../screens/MyProfile';
import CreatePost from '../../screens/CreatePost';
import AddManualEntry from '../../screens/AddManualEntry';
import UserDetailForm from '../../screens/UserDetailForm';
import MyWorkout from '../../screens/MyWorkout';
import MyActivity from '../../screens/MyActivity';
import LeadershipBoard from '../../screens/LeadershipBoard';
import EditWorkout from '../../screens/EditWorkout';
import editProfile from '../../screens/editProfile';
import MyReport from '../../screens/MyReport';
import Mycertificate2022 from '../../screens/Mycertificate2022';
import Mycertificate2021 from '../../screens/Mycertificate2021';
import Mycertificate2023 from '../../screens/Mycertificate2023';

import Mycertificatecurrent from '../../screens/Mycertificatecurrent';
import DandiDadhboard from '../../screens/DandiDadhboard';
import NavratriDashboard from '../../screens/NavratriDashboard';
import AyodhyaDashboard from '../../screens/AyodhyaDashboard';

import weeklyGraph from '../../components/weeklyGraph';
import MonthlyGraph from '../../components/MonthlyGraph';


export const authStack = () => {
    return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/home" component={Home} />
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/user-detailform" component={UserDetailForm}/>
          </Switch>
        </BrowserRouter>
    );
  };


export const appStack = () => {
    return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/">
                <Redirect to="/my-workout" />
            </Route>
            {/* <Route exact path="/user-detailform" component={UserDetailForm}/> */}
            <Route exact path="/profile" component={MyProfile}/>
            <Route exact path="/create-post" component={CreatePost}/>
            <Route exact path="/add-manual" component={AddManualEntry}/>
            <Route exact path="/my-workout" component={MyWorkout}/>
            <Route exact path="/my-activity" component={MyActivity}/>
            <Route exact path="/leader-board"  component={LeadershipBoard}/>
            <Route exact path="/editworkout" component={EditWorkout}/>
            <Route exact path="/edit-profile" component={editProfile}/>
            <Route exact path="/myreport" component={MyReport}/>
            <Route exact path="/certificate2021" component={Mycertificate2021}/>
            <Route exact path="/certificate2022" component={Mycertificate2022}/>
            <Route exact path="/certificate2023" component={Mycertificate2023}/>
            <Route exact path="/certificatecurrent" component={Mycertificatecurrent}/>

            <Route exact path="/dandi" component={DandiDadhboard} />
            <Route exact path="/navratri" component={NavratriDashboard} />
            <Route exact path="/ayodhyarunwalk" component={AyodhyaDashboard} />

            
            {/* <Route exact path="/test" component={weeklyGraph}/> */}
            {/* <Route exact path="/test2" component={MonthlyGraph}/> */}
         

          </Switch>
        </BrowserRouter>
    );
  };

