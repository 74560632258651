import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {AsyncStorageService} from '../services/AsyncStorage';

import MyworkoutRecord from '../components/MyworkoutRecord';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {UserData} from '../services/UserData';
import cookie from 'react-cookies';
import $ from 'jquery';

interface Props {
    history:any;
    location:any;
}
interface State {
    [x: number]: any; 
    yearList: any;
    monthList: any;
    month:any;
    year:any;
    type:any;
    currentYear:any;
    User:any;
    isStravaUser:any;
    overview:any;
    profile:any;
    monthlyData:any;
    newMonthly:any;
    popup:any;
}
class MyWorkout extends React.Component<Props, State> {
    child:any;
    constructor(props: Props) {
        super(props);
        this.state = {
            yearList: [],
            monthList: [],
            month:'',
            year:'',
            currentYear:'',
            type:'all',
            User:'',
            isStravaUser:'',
            overview:[],
            profile:[],
            monthlyData:[],
            newMonthly:'',
            popup:''
        }
        this.child = React.createRef();
    }
    yearNmonth() {
        var currentYear = (new Date()).getFullYear();
        var currentMonth = (new Date()).getMonth()+1;
        const yearList: any = []
        for (var i = 2000; i <= currentYear; i++) {
            yearList.push(i);
        }
        const monthList = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        this.setState({yearList,monthList,currentYear,year:currentYear,month:currentMonth });
        this.workoutOverview(currentYear,currentMonth);
        this.onInputChange(currentMonth,currentYear);
        this.onStairMonthChange(currentMonth,currentYear);
    }
    workoutOverview=async(year,month)=>{
        this.setState({year});
       // this.onInputChange(month,year);
        const User = await AsyncStorageService.getUser();
        const userId = User.user_id;
        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
          let formData = new FormData();

          formData.append('user_id',userId);
          formData.append('year',year);
          formData.append('month',month);
      
        await axios.post("https://api.cyruns.in/api/athletes.php?action=overview",formData,init)
        .then(response => {
           if(response.data.msgcode == 0){
            this.setState({overview: response.data.overview});
           }else{
            toast.error(response.data.msg);
           }
        })
    }
    resateState = () => {
        setTimeout(() => {
            this.props.history.replace();
          }, 3000); 
    }
    myProfile=async()=>{
        const data = await UserData.getUserData();
        const pro = data.profile[0];
        const userImage = data.userImage;
        this.setState({profile:pro});
    }
    componentDidMount=async()=> {
       
        const Msg = this.props.location.state;
        if(Msg){
             toast.success(Msg);
             this.resateState();
        }
        this.yearNmonth();
        this.myProfile();
        const search = this.props.location.search;
        if(search!=''){
            const Code = search.split("&")[1].split('=')[1];
            this.getStravaToken(Code);
        }
        const User = await AsyncStorageService.getUser();
        this.setState({User});

        const isStravaUserL:any = cookie.load('is_strava_user');
        const struser = User.is_strava_user;
        // if(isStravaUserL == 1 || struser == 1){
        //     this.syncWithStrava();
        // }
        this.eventPopup();
    }
    eventPopup=async()=>{
        let init:any = {
            method: 'GET',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
        await axios.get("https://api.cyruns.in/api/athletes.php?action=popup",init)
        .then(response => {
            if(response.data.msgcode == 0){
              this.setState({popup:response.data.result});
              const id = document.getElementById('showEventModel');
                  $(id).click();
            }
        })
    }
    getStravaToken=async(Code)=>{
        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
          let formData = new FormData();

          formData.append('client_id','58835');
          formData.append('client_secret','43e013613c045dcb9c733f5a53550400beebe779');
          formData.append('code',Code);
          formData.append('grant_type','authorization_code');
        await axios.post("https://www.strava.com/api/v3/oauth/token", formData,init)
        .then(response => {
            this.connectWithStrava(response);
        })
    }
    connectWithStrava=async(response)=>{
        const {User} = this.state;
        const userId = User.user_id;
        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
          let formData = new FormData();

          formData.append('user_id',userId);
          formData.append('response',JSON.stringify(response.data));
      
        await axios.post("https://api.cyruns.in/api/athletes.php?action=capture_strava",formData,init)
        .then(response => {
           if(response.data.msgcode == 0){
               toast.success(response.data.msg);
               this.props.history.push('/my-workout');
              // sessionStorage.setItem('is_strava_user','1');
               cookie.save('is_strava_user', '1', { path: '/' , maxAge: 43200});
               this.setState({isStravaUser:1});
               this.syncWithStrava();
           }else{
            toast.error(response.data.msg);
           }
        })
    }
    syncWithStrava=async()=>{
        const {User} = this.state;
        const userId = User.user_id;
        var currentYear = (new Date()).getFullYear();
        var currentMonth = (new Date()).getMonth()+1;
        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
          let formData = new FormData();

          formData.append('user_id',userId);
      
        await axios.post("https://api.cyruns.in/api/athletes.php?action=get_activities",formData,init)
        .then(response => {
           if(response.data.msgcode == 0){
             toast.success(response.data.msg);
             this.workoutOverview(currentYear,currentMonth);
               if(this.child.current !=null){
                this.child.current.myWorkoutall();
             }    
           }else{
            toast.error(response.data.msg);
           }
        })
    }
   
    onInputChange=async(e,yr)=>{
        var currentYear = (new Date()).getFullYear();
        const {year} = this.state;
        let Month;
        let Year;
        if(e > 0 || e < 13){
            Month = e
            this.setState({month:e});
        }else{
            Month = e.target.value;
            this.setState({month:e.target.value});
        }
        
        if(yr !=''){
            Year = yr
        }else{
            Year = currentYear
        }
      
        const User = await AsyncStorageService.getUser();
        const userId = User.user_id;
        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
          let formData = new FormData();

          formData.append('user_id',userId);
          formData.append('year',Year);
          formData.append('month',Month);
      
        await axios.post("https://api.cyruns.in/api/athletes.php?action=monthlyoverview",formData,init)
        .then(response => {
           if(response.data.msgcode == 0){
          this.setState({monthlyData:response.data.overview});
           }else{
            toast.error(response.data.msg);
           }
        })
    }
   
    onStairMonthChange=async(e,yr) => {
        var currentYear = (new Date()).getFullYear();
        const {year} = this.state;
        let Month;
        let Year;
        if(e > 0 || e < 13){
            Month = e
            this.setState({month:e});
        }else{
            Month = e.target.value;
            this.setState({month:e.target.value});
        }
        
        if(yr !=''){
            Year = yr
        }else{
            Year = currentYear
        }
      
        const User = await AsyncStorageService.getUser();
        const userId = User.user_id;
        let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
          let formData = new FormData();

          formData.append('user_id',userId);
          formData.append('year',Year);
          formData.append('month',Month);
      
        await axios.post("https://api.cyruns.in/api/athletes.php?action=monthlyoverviewnew",formData,init)
        .then(response => {
           if(response.data.msgcode == 0){
            this.setState({newMonthly:response.data.overview});
           }else{
            toast.error(response.data.msg);
           }
        })
    }
  
   
    render() {
        const {monthList,year,month,currentYear,User,isStravaUser,overview,profile,
            monthlyData,newMonthly,popup} = this.state;
        const isStravaUserL:any = cookie.load('is_strava_user');
        const Cycl = overview.cycling;
        const Run = overview.running;
        const Walk = overview.walking;
        const Total = overview.total;

        const Stairs = overview.stairs;
        const Steps = overview.steps;
        const Exercise = overview.exercise;
        const stairsTotal = overview.stairs_total;
        const Monthkm = monthlyData.monthly_km;
        const Monthrank = monthlyData.monthly_rank;

        const newMkm = newMonthly.monthly_km;
        const newMrank = newMonthly.monthly_rank;
        var client_id = '58835';
        var redirectURI = 'https://athletes.cyruns.in/my-workout';

        var baseUrl = 'https://www.strava.com/oauth/authorize?client_id='+ client_id +'&redirect_uri='+ redirectURI;
        var uri = '&response_type=code&approval_prompt=auto&scope=read_all,read,activity:read_all,profile:read_all,activity:write';
        var enc = encodeURIComponent(uri);
        const Furi = baseUrl + uri;

        return (
            <div className='myworkout-main container sec_workout'>
                <ToastContainer/>
                <a href="#" className="btn stage_btn" id='showEventModel'
                    data-toggle="modal" data-target="#eventRegisterModel" style={{ display: 'none' }}></a>
                <div className="section-title-first">
                         <div className="challenger-id">
                            <span>Athlete ID</span> 
                            <p>{profile.athlete_id}</p>
                        </div>
                    <h1>My Workout</h1>
                </div>

                <div className='row'>
                    <div className="col-md-12">
                        <div className="wo_t5_filter  text-center">
                            <div className="btn-group" role="group" aria-label="Filter Top 5 Athlete">
                                <button type="button"  className={year == currentYear - 1?"btn btn-secondary filter_btn active":"btn btn-secondary filter_btn"}  
                                onClick={() =>this.workoutOverview(currentYear - 1,this.state.month)}>{currentYear - 1}</button>

                                <button type="button" className={year == currentYear?"btn btn-secondary filter_btn active":"btn btn-secondary filter_btn"} 
                                onClick={() =>this.workoutOverview(currentYear,this.state.month)}>{currentYear}</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="summaryTitle">
                                   

                                    {User.is_strava_user == 1 || isStravaUser == 1 || isStravaUserL==1?
                                        <button type="button" className="btn btn-primary" onClick={this.syncWithStrava}>
                                            Sync With Strava
                                         <span><i className="fa fa-refresh"></i></span>
                                        </button>:
                                        <button type="button" className="btn btn-primary">
                                            <a href={Furi}>
                                            Connect With Strava
                                            <span><i className="fa fa-plus"></i></span>
                                            </a>
                                        </button>
                                    }
                                   
                                    <p>Your Workout Summary Since Joined</p>
                                    <button type="button" className="btn btn-primary" 
                                    onClick={()=>{ this.props.history.push('/add-manual')}}>Add Workout
                                      <span><i className="fa fa-plus"></i></span>
                                      
                                    </button>
                                </div>
                                {/* <div className='addworkout-btn'>
                                 <button type="button" className="btn btn-primary">Add Workout
                                     <span><i className="fa fa-plus"></i></span>
                                 </button>
                                </div> */}

                            </div>
                        </div>
                        <div className="table-responsive">
                        <table className="table table-striped t5_block" id="filter_data">
                            <thead>
                                <tr className="our_work_wrap_inner">
                                    <td className="text-left"><b>Overview</b></td>
                                    <td>
                                        <b title="Cycling" className="icon-activity">
                                        <img src="./Images/cyruns_cyclist.svg" />
                                        </b>
                                        <span className="title-activity">Cycling</span>
                                    </td>

                                    <td>
                                        <b title="Running" className="icon-activity">
                                        <img src="./Images/cyruns_runner.svg" />
                                        </b>
                                        <span className="title-activity">Running</span>
                                    </td>
                                   
                                    <td>
                                        <b title="Walking" className="icon-activity">
                                        <img src="./Images/cyruns_walking.svg" />
                                        </b>
                                        <span className="title-activity">Walking</span>
                                    </td>
                                    <td className="text-center"><b>Total</b></td>
                                </tr>
                            </thead>
                            {overview !=''?
                             <tbody className="t5_block" id="dt_filter">
                             <tr>
                                 <td className="text-left">Total Timed Event Score:</td>
                                 <td>{Cycl.ttescore}</td>
                                 <td>{Run.ttescore}</td>
                                 <td>{Walk.ttescore}</td>
                                 <td>{Total.ttescore}</td>
                             </tr>
                             <tr>
                                 <td className="text-left">Total KM:</td>
                                 <td>{Cycl.tkm}</td>
                                 <td>{Run.tkm}</td>
                                 <td>{Walk.tkm}</td>
                                 <td>{Total.tkm}</td>
                             </tr>
                             <tr>
                                 <td className="text-left">Total Time:</td>
                                 <td>{Cycl.ttime}</td>
                                 <td>{Run.ttime}</td>
                                 <td>{Walk.ttime}</td>
                                 <td>{Total.ttime}</td>
                             </tr>
                             <tr>
                                 <td className="text-left">Calories Burned:</td>
                                 <td>{Cycl.calories}</td>
                                 <td>{Run.calories}</td>
                                 <td>{Walk.calories}</td>
                                 <td>{Total.calories}</td>
                             </tr>
                             <tr>
                                 <td className="text-left">All Over Ranking:</td>
                                 <td>{Cycl.rank}</td>
                                 <td>{Run.rank}</td>
                                 <td>{Walk.rank}</td>
                                 <td>-</td>
                             </tr>
                             {/* <tr>
                                 <td className="text-left">Gender Ranking:</td>
                                 <td>{Cycl.gender_rank}</td>
                                 <td>{Run.gender_rank}</td>
                                 <td>{Walk.gender_rank}</td>
                                 <td>-</td>
                             </tr> */}
                             <tr>
                                <td className="text-left mo_tt">
                                    <select className="form-control filter-rs" id="custom_filter[return_duration]"
                                        name='month' onChange={(e)=>this.onInputChange(e,this.state.year)}>
                                        <option value="">Month</option>
                                        {monthList != null && monthList != '' ?
                                            monthList.map((Month, i) => {
                                                return <option value={i + 1} key={i}
                                                    selected={month == i+1?true:false}>{Month}</option>
                                            })
                                            :
                                        <option value="">Nos.</option>
                                        } 
                                    </select>
                                 </td>
                                 <td className="mo_rn">Cycling</td>
                                 <td className="mo_rn">Running</td>
                                 <td className="mo_rn">Walking</td>
                                 <td className="mo_rn">-</td>
                             </tr>
                             {Monthkm?
                             <tr>
                                <td className="text-left mo_tt">
                                    Monthly KM
                                </td>
                                <td className="mo_rn">{Monthkm.cycling}</td>
                                <td className="mo_rn">{Monthkm.running}</td>
                                <td className="mo_rn">{Monthkm.walking}</td>
                                <td className="mo_rn">{Monthkm.total_km}</td>
                             </tr>
                             :
                            <tr>
                                <td colSpan={5}>No Data Found...!</td>
                            </tr>
                             }
                            {Monthrank?
                             <tr>
                                <td className="text-left mo_tt">
                                    Monthly Ranking
                                </td>
                                <td className="mo_rn">{Monthrank.cycling}</td>
                                <td className="mo_rn">{Monthrank.running}</td>
                                <td className="mo_rn">{Monthrank.walking}</td>
                                <td className="mo_rn">-</td>
                            </tr>
                            :
                            <tr>
                                <td colSpan={5}>No Data Found...!</td>
                            </tr>
                             }
                         </tbody>
                            :
                            
                            <tbody className="t5_block" id="dt_filter">
                                <tr>
                                    <td colSpan={5}>No Data Found...!</td>
                                </tr>
                            </tbody>
                            }
                           
                        </table>
                        </div>

{/* steps, stairs,exercise table */}
                    <div className="table-responsive">
                        <table className="table table-striped t5_block" id="filter_data">
                            <thead>
                                <tr className="our_work_wrap_inner">
                                    <td className="text-left"><b>Overview</b></td>
                                    <td>
                                        <b title="Stairs" className="icon-activity">
                                        <img src="./Images/stairs.svg" />
                                        </b>
                                        <span className="title-activity">Stairs</span>
                                    </td>

                                    <td>
                                        <b title="Steps" className="icon-activity">
                                        <img src="./Images/steps.svg" />
                                        </b>
                                        <span className="title-activity">Steps</span>
                                    </td>
                                   
                                    <td>
                                        <b title="Exercise" className="icon-activity">
                                        <img src="./Images/exercise.svg" />
                                        </b>
                                        <span className="title-activity">Exercise</span>
                                    </td>
                                    <td className="text-center"><b>Total</b></td>
                                </tr>
                            </thead>
                            {overview !=''?
                             <tbody className="t5_block" id="dt_filter">
                             <tr>
                                 <td className="text-left">Total Timed Event Score:</td>
                                 <td>{Stairs.ttescore}</td>
                                 <td>{Steps.ttescore}</td>
                                 <td>{Exercise.ttescore}</td>
                                 <td>{stairsTotal.ttescore}</td>
                             </tr>
                             <tr>
                                 <td className="text-left">Total Count:</td>
                                 <td>{Stairs.stairs}</td>
                                 <td>{Steps.steps}</td>
                                 <td>{Exercise.exercise}</td>
                                 <td>{stairsTotal.tkm}</td>
                             </tr>
                             <tr>
                                 <td className="text-left">Total Time:</td>
                                 <td>{Stairs.ttime}</td>
                                 <td>{Steps.ttime}</td>
                                 <td>{Exercise.ttime}</td>
                                 <td>{stairsTotal.ttime}</td>
                             </tr>
                             <tr>
                                 <td className="text-left">Calories Burned:</td>
                                 <td>{Steps.calories}</td>
                                 <td>{Stairs.calories}</td>
                                 <td>{Exercise.calories}</td>
                                 <td>{stairsTotal.calories}</td>
                             </tr>
                             <tr>
                                 <td className="text-left">All Over Ranking:</td>
                                 <td>{Stairs.rank}</td>
                                 <td>{Steps.rank}</td>
                                 <td>{Exercise.rank}</td>
                                 <td>-</td>
                             </tr>
                             <tr>
                                <td className="text-left mo_tt">
                                    <select className="form-control filter-rs" id="custom_filter[return_duration]"
                                        name='month' onChange={(e)=>this.onStairMonthChange(e,this.state.year)}>
                                        <option value="">Month</option>
                                        {monthList != null && monthList != '' ?
                                            monthList.map((Month, i) => {
                                                return <option value={i + 1} key={i}
                                                    selected={month == i+1?true:false}>{Month}</option>
                                            })
                                            :
                                        <option value="">Nos.</option>
                                        } 
                                    </select>
                                 </td>
                                 <td className="mo_rn">Stairs</td>
                                 <td className="mo_rn">Steps</td>
                                 <td className="mo_rn">Exercise</td>
                                 <td className="mo_rn">-</td>
                             </tr>
                             {newMkm?
                             <tr>
                                <td className="text-left mo_tt">
                                    Monthly KM
                                </td>
                                <td className="mo_rn">{newMkm.stairs}</td>
                                <td className="mo_rn">{newMkm.steps}</td>
                                <td className="mo_rn">{newMkm.exercise}</td>
                                <td className="mo_rn">{newMkm.total_count}</td>
                             </tr>
                             :
                            <tr>
                                <td colSpan={5}>No Data Found...!</td>
                            </tr>
                             }
                            {newMrank?
                             <tr>
                                <td className="text-left mo_tt">
                                    Monthly Ranking
                                </td>
                                <td className="mo_rn">{newMrank.stairs}</td>
                                <td className="mo_rn">{newMrank.steps}</td>
                                <td className="mo_rn">{newMrank.exercise}</td>
                                <td className="mo_rn">-</td>
                            </tr>
                            :
                            <tr>
                                <td colSpan={5}>No Data Found...!</td>
                            </tr>
                             }
                         </tbody>
                            :
                        <tbody className="t5_block" id="dt_filter">
                            <tr>
                                <td colSpan={5}>No Data Found...!</td>
                            </tr>
                        </tbody>
                        }
                        </table>
                        </div>
                    </div>
{/* event popup */}
                    <div className="modal fade show" id="eventRegisterModel" role="dialog" >
                        <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header viewDetailheader">
                                <h5 className="modal-title" id="exampleModalLongTitle">Register Now</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                             <div className='popup_image'>
                                <img src={popup.image} style={{width: '100%'}}/>                                
                            </div>
                            <div className='popup_dec'>
                                <p>{popup.short_desc}</p>
                            </div>
                            </div>
                            <div className="modal-footer">
                                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                                <a href={popup.link} target="_blank" className="btn btn-primary">Register Now</a>
                            </div>
                            </div>
                        </div>
                    </div>
                    <MyworkoutRecord history={this.props.history}  ref={this.child}/>
                    
            </div>
            </div>
        )
    }
}

export default MyWorkout;