import * as React from 'react';
import $ from 'jquery';
interface Props {
    Title:any;
}
interface State {
    test:any;
}

class ReportViewModel extends React.Component<Props, State>{
    constructor(props){
        super(props);
        this.state ={
            test:[]
        }
    }


    componentDidMount=()=>{

        const test:any = [];
        for(let i = 1; i <= 10;i++){
            test.push(i);
        //    return (
        //     <tr>
        //         <th>{i}</th>
        //         <td>Ankit sabhadiya</td>
        //         <td>10</td>
        //         <td>20</td>
        //         <td>30</td>
        //     </tr>
        //    ) 
        }
        this.setState({test});
        const Id = document.getElementById('report_viewTable');
      
       // ($(Id) as any).DataTable();
    }

    render() {
        const {Title} = this.props;
        const {test} = this.state;
        // $(document).ready(function () {
        //     ($('#report_viewTable') as any).DataTable();
        // });
        return (
            <div className="modal-content fullscreenModelContent">
            <div className="modal-header">
                <h5 className="modal-title">
                    <div className="rank_img_modal">
                        <img src={'./Images/madel.png'} />
                    </div>
                    <span className="title-test">{Title} rank list</span>
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div className="modal-body">
                <div className="loader">
                    {/* <input type="text" name="search" id="keyword_5" className="form-control"
                        placeholder="Search Your Rank" style={{ textAlign: 'center', marginBottom: 10 }}
                        onChange={this.searchSpace} autoComplete="off"/> */}
                    <div className="">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered report_viewtable" id="report_viewTable">
                                <thead>
                                    <tr>
                                        <th>SR No</th>
                                        <th>Name</th>
                                        <th colSpan={2}>Cycling</th>
                                        <th colSpan={2}>Running</th>
                                        <th colSpan={2}>Walking</th>
                                    </tr>
                                </thead>
                          
                                <tbody>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Rank</th>
                                    <th>KM</th>
                                    <th>Rank</th>
                                    <th>KM</th>
                                    <th>Rank</th>
                                    <th>KM</th>
                                </tr>
                                {
                                    test.map((data,i)=>{
                                return   <tr key={i}>
                                            <td>{data}</td>
                                            <td>Ankit Sabhadiya</td>
                                            <td>1</td>
                                            <td>100</td>
                                            <td>2</td>
                                            <td>50</td>
                                            <td>3</td>
                                            <td>25</td>
                                        </tr>
                                     
                                   
                                      })
                                    }
                                    {/* <tr>
                                        <th>1</th>
                                        <td>Ankit sabhadiya</td>
                                        <td>1</td>
                                        <td>20</td>
                                        <td>3</td>
                                        <td>30</td>
                                        <td>4</td>
                                        <td>40</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Jitendr parmar</td>
                                        <td>
                                            <td>10</td>
                                            <td>20</td>
                                        </td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>3</th>
                                        <td>Rathod Mukesh</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>4</th>
                                        <td>Aman Tilak</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>5</th>
                                        <td>Ankit sabhadiya</td>
                                        <td>10</td>
                                        <td>20</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th>6</th>
                                        <td>Jitendr parmar</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Rathod Mukesh</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Aman Tilak</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Ankit sabhadiya</td>
                                        <td>10</td>
                                        <td>20</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Jitendr parmar</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Rathod Mukesh</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Aman Tilak</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Aman Tilak</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Ankit sabhadiya</td>
                                        <td>10</td>
                                        <td>20</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Jitendr parmar</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Rathod Mukesh</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <th>2</th>
                                        <td>Aman Tilak</td>
                                        <td>12</td>
                                        <td>18</td>
                                        <td>22</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="modal-footer fullscreenModelFooter">
                <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-light btnclodemodule" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
        )
    }
}

export default ReportViewModel;