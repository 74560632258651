import * as React from 'react';
import axios from 'axios';

import Avatar from 'react-avatar';
import Button from 'muicss/lib/react/button';
import If from '../components/If';
import { ToastContainer, toast } from 'react-toastify';
import Dropzone from 'react-dropzone';

import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import {AsyncStorageService} from '../services/AsyncStorage';

import '../styles/createPost.css';


interface Props{
  history:any;
}
interface State {
  [x: number]: any;
    selectedFile:any;
    title:any;
    description:any;
    loader:boolean;
    userName:any;
    userId:any;
}

class CreatePost extends React.Component<Props,State>{
    constructor(props){
       super(props);
        this.state ={
            selectedFile:[],
            title:'',
            description:'',
            loader:false,
            userName:"",
            userId:'' 
        };
    }
    async componentDidMount(){
        const user =  await AsyncStorageService.getUser();
        this.setState({userId:user.user_id, userName:user.firstname +' '+ user.lastname});
      }
    onFileChange = files => { 
      
       if(files.length <= 5){
        this.setState({selectedFile: files }); 
       }else{
        toast.error("You have to upload Maximum 5 images!");
       }
      }; 

      imageRemove(file){
        const {selectedFile} = this.state;
       for(let i=0;i<selectedFile.length;i++){
         if(selectedFile[i] == file){
           selectedFile.splice(i, 1);
           this.setState({selectedFile});
         }
       }
      }
      
      onInputChange=(e: any)=> {
        const { name, value } = e.target;
        this.setState({[name]: value});
      }
  

     async PublishPost(e){
        e.preventDefault();
        const {title,description,selectedFile,userId} = this.state;
        if(title!='' && description!='' && selectedFile!='' && userId!=''){
          this.setState({loader:true});
          let init:any = {
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data',
                        'Accept': 'application/json'},
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async:    true,
          };
         
        let formData = new FormData();
        formData.append('user_id',userId);
        formData.append('post_type','normal');
        formData.append('post_title',title);
        formData.append('post_desc',description);
        formData.append('media_count',selectedFile.length);
        selectedFile.forEach((image_file,index) => {
          formData.append('post_media'+(index+1), image_file);
      });
       
        await axios.post("https://api.cyruns.in/api/athletes.php?action=create-post", formData,init)
        .then(response => {
          this.setState({loader:false});
          if(response.data.msgcode == 0){
              toast.success(response.data.msg);
              this.props.history.push({ 
                  pathname: '/dashboard',
                  state: response.data.user
                 });
                 window.location.reload(false);
          }
          else{
              toast.error(response.data.msg);
          }
        })
        .catch(error => {
            const Msg='Something went to wrong please Try later... OR Check your internet connection';
              toast.error(Msg);
               this.setState({loader:false});
              console.log(error);
         });
        }else{
          toast.error('please fill all required fields');
        }
       
      }
     
     render(){
      const {selectedFile,userName,loader} = this.state;
       const dropzoneRef:any = React.createRef();
        return(
            <div className="createpost_page">
              <ToastContainer/>
              <div className='top_section'>
                <p>Create a new post</p>
              </div>
              <div className='new_contain'>
                <div className='pos_usrname'>
                  <Avatar name={userName} size='80px' round={true} color='#ca7245' style={{fontWeight:600}}/>
                    <p>{userName}</p>
                  {!loader?
                    <Button className='publis_btn' onClick={this.PublishPost.bind(this)}>Publish</Button>
                     :
                    <Button className='publis_btn' onClick={this.PublishPost.bind(this)}>
                          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading...
                    </Button>
                  }
                </div>
                <div className='crpost_inputs'>
                 <textarea className="form-control" id="createPostTitle" placeholder="Add a title (Optinal)" rows={1} style={{fontSize:25}}
                   name='title' onChange={(e)=>this.onInputChange(e)}></textarea>
                 <textarea className="form-control" id="createPostdescription" placeholder="what's going on?" rows={4} style={{fontSize:15}}
                   name='description' onChange={(e)=>this.onInputChange(e)}></textarea>
                </div>

                <div className="post_imgupload" >
                 {selectedFile !=null && selectedFile!=''?
                 selectedFile.map((file,index)=>{
                 
                   if( file.type == 'video/mp4'){
                    return  (<div key={index} className='crp_sel_video'>
                              <Player>
                                    <source src={URL.createObjectURL(file)} />
                              </Player>
                              <i className="fa fa-times-circle-o" aria-hidden="true" onClick={()=>this.imageRemove(file)}></i>
                             </div>);
                   }else{
                    return  (
                          <div className='crp_imgdisply' key={index}>
                            <img src={URL.createObjectURL(file)} /> 
                            <i className="fa fa-times-circle-o" aria-hidden="true" onClick={()=>this.imageRemove(file)}></i>
                         </div>
                    )
                   }
                  
                   

                  
                 }):
                  <Dropzone  ref={dropzoneRef} onDrop={(acceptedFiles)=>this.onFileChange(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <label className="img_upldico" htmlFor='post_imgupload_inpt'>
                          <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                          <p>Drag and drop OR Click to upload Photo</p>
                      </label>
                      </div>
                    )}
                  </Dropzone>
                 } 
                </div>

              </div>
            </div>
        )
    }

}
export default CreatePost;